import React, { useEffect } from "react";
import styles from "./Landing.module.scss";
import { useHistory } from "react-router";
import { useFirstRender } from "./FirstRenderHook";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../redux/types";
import { signout } from "../../redux/actions";

function Landing() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isFirstRender = useFirstRender();
  const token = useSelector((state: IState) => state.token);

  useEffect(() => {
    if (isFirstRender) {
      if (token.length > 1) {
        dispatch(
          signout(() => {
            history.push("/");
          })
        );
      }
    }
  }, []);

  return <div className={styles.container}></div>;
}

export default Landing;
