import { useEffect } from "react";
import { Spin, Form, Input, Button, Row, Col, Space, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { addVessel, getVessels } from "../../../../redux/actions";
import { IState, IVessel } from "../../../../redux/types";

import styles from "../Vessel.module.scss";

interface IProps {
  data: IVessel | undefined;
  closeModal: () => void;
}

const { Option } = Select;

function InvoiceModal({ data, closeModal }: IProps) {
  const addVesselState = useSelector((state: IState) => state.addVessel);
  const vesselRelations = useSelector((state: IState) => state.vesselRelations);
  const vesselsState = useSelector((state: IState) => state.vessels);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <Spin spinning={addVesselState.loading}>
        <Form
          layout="vertical"
          initialValues={{
            ...data,
            registerDate: data?.registerDate
              ? new Date(parseInt(data?.registerDate))
                  .toISOString()
                  ?.slice(0, 10)
              : "",
          }}
          form={form}
          onFinish={(info) => {
            dispatch(
              addVessel(
                data ? "PUT" : "POST",
                {
                  ...info,
                  callSign: info.callSign ? info.callSign : "",
                  captainName: info.captainName ? info.captainName : "",
                  class: info.class ? info.class : "",
                  contactNo: info.contactNo ? info.contactNo : "",
                  imoCode: info.imoCode ? info.imoCode : "",
                  registerDate: info.registerDate
                    ? moment(info.registerDate).format("x")
                    : "",
                  buildYear: info.buildYear ? Number(info.buildYear) : "",
                  registerNo: info.registerNo
                    ? info.registerNo.toEnDigit()
                    : "",
                  tonnage: info.tonnage ? info.tonnage.toEnDigit() : "",
                  teu: info.teu ? info.teu.toEnDigit() : "",
                  draft: info.draft ? Number(info.draft) : "",
                },
                () => {
                  form.resetFields();
                  closeModal();
                  dispatch(
                    getVessels({
                      limit: vesselsState.params.limit,
                      page: vesselsState.params.page,
                    })
                  );
                },
                data?.id
              )
            );
          }}
        >
          <div className={styles.detailContainer}>
            <Row gutter={[30, 10]}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label="نام شناور"
                  name="name"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label="نوع شناور"
                  name="vesselTypeId"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Select allowClear showSearch optionFilterProp="children">
                    {vesselRelations.data.vesselTypes.map((item: any) => (
                      <Option value={item.id}>{item.generalName}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="registerPortId"
                  label="بندر ثبت"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Select allowClear showSearch optionFilterProp="children">
                    {vesselRelations.data.registerPorts.map((item: any) => (
                      <Option value={item.id}>{item.locationName}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6}>
                <Form.Item name="buildYear" label="سال ساخت">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6}>
                <Form.Item name="registerNo" label="شماره ثبت">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="registerDate" label="تاریخ ثبت">
                  <Input allowClear type="date" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="class" label="تحت کلاس">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="flagId"
                  label="پرچم"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Select allowClear showSearch optionFilterProp="children">
                    {vesselRelations.data.flags.map((item: any) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="callSign"
                  label="علامت خطاب
                  Call Sign"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6}>
                <Form.Item name="imoCode" label="IMO number">
                  <Input disabled={data ? true : false} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6}>
                <Form.Item name="tonnage" label="ظرفیت (DWT)">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6}>
                <Form.Item name="teu" label="ظرفیت (TEU)">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6}>
                <Form.Item name="draft" label="درافت">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="captainName" label="نام فرمانده">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="contactNo" label="شماره تماس">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24} className="alignEnd">
              <Space size={16}>
                <Button htmlType="submit" size="large" type="primary">
                  ذخیره
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}

export default InvoiceModal;
