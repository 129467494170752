import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { docTypes, getMyDocTypes } from "../../../../redux/actions";
import CustomizedTables from "./DocsTable";
import api from "../../../../utils/api";
import { toast } from "react-toastify";

export default function ForgotPass({
  handleClose,
  open,
  docsModalCarryOrderID,
}: any) {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  React.useEffect(() => {
    dispatch(docTypes());
    dispatch(getMyDocTypes(docsModalCarryOrderID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  React.useEffect(() => {
    if (docsModalCarryOrderID !== 0) {
      dispatch(getMyDocTypes(docsModalCarryOrderID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docsModalCarryOrderID, refresh]);

  const handleUpload = (file: any, idDOC: any) => {
    console.log("idDOC::", idDOC);
    var bodyFormData = new FormData();
    bodyFormData.append("doc", file);
    api({
      isFile: true,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `v1.0.0/document/uploadDoc?docTypeId=${idDOC}&carryOrderId=${docsModalCarryOrderID}`,
      baseUrl: "https://appsrv1.beautyles.com:8052",
      auth: true,
      data: bodyFormData,
      success: () => {
        toast.success("سند شما آپلود شد.");
        setRefresh((pre) => !pre);
      },
      error: () => {
        toast.error("سند شما آپلود نشد.");
      },
    });
  };

  return (
    <>
      <CustomModal
        hasCloseIcon
        open={open}
        handleClose={handleClose}
        title="اسناد"
      >
        <CustomizedTables
          handleUpload={handleUpload}
          docsModalCarryOrderID={docsModalCarryOrderID}
        />
      </CustomModal>
    </>
  );
}
