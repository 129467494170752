import { useState, useEffect } from 'react'
import { Spin, Space, Button, Tag, Table, Modal, TablePaginationConfig } from 'antd'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmModal from '../../../components/ConfirmModal'
import Card from '../../../components/Card/Card'
import { getVessels, deleteVessel } from '../../../redux/actions'
import { IState, IVessel } from '../../../redux/types'
import ShowModal from './Modals/ShowModal'
import VesselModal from './Modals/VesselModal'

function Vessel() {
  const vessels = useSelector((state: IState) => state.vessels)
  const vesselRelations = useSelector((state: IState) => state.vesselRelations)
  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState<[boolean, IVessel | undefined]>([false, undefined])
  const [confirmModal, setConfirmModal] = useState<[boolean, number | undefined]>([false, undefined])
  const [addVesselModal, setAddVesselModal] = useState<[boolean, IVessel | undefined]>([false, undefined])

  useEffect(() => {
    dispatch(
      getVessels({
        limit: 10,
        page: 1,
      })
    )
  }, [])

  function onChangeTable(pagination: TablePaginationConfig) {
    if (pagination.current && pagination.pageSize) {
      dispatch(getVessels({
        page: pagination.current, 
        limit: pagination.pageSize
      }))
    }
  }

  function onShowSizeChange(current: number, size: number) {
    dispatch(getVessels({
      limit: size,
      page: current,
    }))
  }

  const columns = [
    {
      title: 'نام',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'بندر ثبت',
      dataIndex: 'registerPortId',
      key: 'registerPortId',
      render: (id: number) => <Tag>{vesselRelations.data.registerPorts.find(item => item.id === id)?.locationName}</Tag>
    },
    {
      title: 'ظرفیت (DWT)',
      dataIndex: 'tonnage',
      key: 'tonnage',
    },
    {
      title: 'نوع شناور',
      dataIndex: 'vesselTypeId',
      key: 'vesselTypeId',
      render: (id: number) => <Tag>{vesselRelations.data.vesselTypes.find(item => item.id === id)?.generalName}</Tag>
    },
    {
      title: 'پرچم',
      dataIndex: 'flagId',
      key: 'flagId',
      render: (id: number) => <Tag>{vesselRelations.data.flags.find(item => item.id === id)?.name}</Tag>
    },
    {
      title: 'تحت کلاس',
      dataIndex: 'class',
      key: 'class',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: any, row: IVessel) => {
        return (
          <Space size={25} className="alignEnd">
            <EyeOutlined onClick={() => {
              setShowModal([true, row])
            }} />
            <EditOutlined onClick={() => {
              setAddVesselModal([true, row])
            }} />
            <DeleteOutlined className="deleteIcon" onClick={() => {
              setConfirmModal([true, row.id])
            }} />
          </Space>
        )
      }
    },
  ];

  return (
    <Card>
      <Spin spinning={vessels.loading}>
        <div style={{ justifyContent: 'flex-end', marginBottom: 20 }}>
          <Button type="primary" size="large" onClick={() => {
            setAddVesselModal([true, undefined])
          }}>
            ثبت شناور
          </Button>
        </div>
        <Table
          rowKey='imoCode'
          scroll={{ x: true }}
          loading={false}
          dataSource={vessels.data}
          columns={columns}
          onChange={onChangeTable}
          pagination={{
            total: vessels.params.count,
            pageSize: vessels.params.limit,
            current: vessels.params.page,
            showTotal: (total: number) => `${total} مورد`,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            pageSizeOptions: ['10', '20', '50'],
            showTitle: false,
            locale: {
              items_per_page: ''
            }
          }}
        />
        <Modal
          title="شناور"
          visible={showModal[0]}
          onOk={() => setShowModal([false, undefined])}
          onCancel={() => setShowModal([false, undefined])}
          width={800}
          footer={false}
        >
          <ShowModal data={showModal[1]} />
        </Modal>
        <Modal
          title={addVesselModal[1] ? "ویرایش شناور" : "ثبت شناور"}
          visible={addVesselModal[0]}
          onOk={() => setAddVesselModal([false, undefined])}
          onCancel={() => setAddVesselModal([false, undefined])}
          width={800}
          footer={false}
        >
          <VesselModal data={addVesselModal[1]} closeModal={() => setAddVesselModal([false, undefined])} />
        </Modal>
        <ConfirmModal
          onCancel={() => setConfirmModal([false, undefined])}
          onConfirm={() => {
            if (confirmModal[1]) {
              dispatch(deleteVessel(confirmModal[1], () => {
                setConfirmModal([false, undefined])
                dispatch(
                  getVessels({
                    limit: vessels.params.limit,
                    page: vessels.params.page,
                  })
                )
              }))
            } else {
              setConfirmModal([false, undefined])
            }
          }}
          visible={confirmModal[0]}
        >
          <p style={{ textAlign: 'center' }}>
           آیا مطمین هستید که این شناور حذف شود؟
          </p>
        </ConfirmModal>
      </Spin>
    </Card>
  )
}

export default Vessel
