import { Descriptions } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { IOrder, IState } from "../../../../redux/types";
import styles from "../Home.module.scss";
import React from "react";
import { getOneCarryOrder } from "../../../../redux/actions";

interface IProps {
  data: IOrder | undefined;
  closeModal: () => void;
}

function DocsModal({ data, closeModal }: IProps) {
  const dispatch = useDispatch();
  const consigneeRelations = useSelector(
    (state: IState) => state.consigneeRelations
  );
  const oneCarryOrder = useSelector((state: IState) => state.oneCarryOrder);

  React.useEffect(() => {
    console.log("consigneeRelations::", consigneeRelations);
    console.log("oneCarryOrder::", oneCarryOrder);
    console.log("data::", data);
  }, [oneCarryOrder]);

  React.useEffect(() => {
    dispatch(getOneCarryOrder(data?.id));
  }, [data]);

  return (
    <div>
      <Descriptions title="مشخصات کالا" bordered>
        <Descriptions.Item label="وزن خالص">
          {data?.grossWeight}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="مبدا">
          {
            consigneeRelations.data.loadingPort.find(
              (item) => item.id === data?.loadingPortId
            )?.locationName
          }
        </Descriptions.Item>
        <Descriptions.Item label="وزن ناخالص">
          {data?.netWeight}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="مقصد">
          {
            consigneeRelations.data.dischargingPort.find(
              (item) => item.id === data?.dischargingPortId
            )?.locationName
          }
        </Descriptions.Item>
        <Descriptions.Item label="نوع کالای خطرناک">
          {
            consigneeRelations.data.dgCode.find(
              (item) => item.id === data?.dGCodeId
            )?.generalName
          }
        </Descriptions.Item>
        <Descriptions.Item label="تعداد کالا">{data?.qty}</Descriptions.Item>
        <Descriptions.Item label="نوع بسته بندی">
          {
            consigneeRelations.data.packageType.find(
              (item) => item.id === data?.packageTypeId
            )?.generalName
          }
        </Descriptions.Item>
        <Descriptions.Item span={2} label="ارزش گمرکی کالا">
          {data?.customValue}
        </Descriptions.Item>
        <Descriptions.Item label="واحد ارز">
          {
            consigneeRelations.data.currency.find(
              (item) => item.id === data?.currencyId
            )?.generalName
          }
        </Descriptions.Item>
        <Descriptions.Item span={3} label="زمان تقریبی جهت بارگیری">
          {data?.etl
            ? moment(parseInt(data?.etl)).format("YYYY/MM/DD HH:mm")
            : ""}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="شرح کالا">
          {data?.goodDescription}
        </Descriptions.Item>
      </Descriptions>
      {oneCarryOrder.data?.needContainers && (
        <>
          <hr className={styles.divider} />
          <Descriptions title="مشخصات کانتینرها" bordered>
            {oneCarryOrder.data.containers?.map((item: any, idx: any) => (
              <>
                <Descriptions.Item span={3} label="شماره کانتینر">
                  {idx + 1}
                </Descriptions.Item>
                <Descriptions.Item span={3} label="تعداد">
                  {item.number}
                </Descriptions.Item>
                <Descriptions.Item span={3} label="نوع">
                  {
                    consigneeRelations.data?.containerType.find(
                      (item2: any) => item2.id === Number(item.type)
                    )?.generalName
                  }
                </Descriptions.Item>
                <Descriptions.Item span={3} label="سایز">
                  {
                    consigneeRelations.data?.containerSize.find(
                      (item2: any) => item2.id === Number(item.size)
                    )?.generalName
                  }
                </Descriptions.Item>
                <Descriptions.Item span={3} label="ـــــ">
                  ـــــ
                </Descriptions.Item>
              </>
            ))}
          </Descriptions>
        </>
      )}

      <hr className={styles.divider} />
      <Descriptions title="فرستنده کالا (Shipper)" bordered>
        <Descriptions.Item label="نام و نام‌خانوادگی">
          {data?.shipperFirstNameFa}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="First Name">
          {data?.shipperFirstNameEn}
        </Descriptions.Item>
        <Descriptions.Item label="نام خانوادگی">
          {data?.shipperLastNameFa}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Last Name">
          {data?.shipperLastNameEn}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="شماره تماس">
          {data?.shipperPhoneNumber}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="آدرس">
          {data?.shipperAddress}
        </Descriptions.Item>
      </Descriptions>
      <hr className={styles.divider} />
      <Descriptions title="گیرنده کالا (Consignee)" bordered>
        <Descriptions.Item label="نام">
          {data?.consigneeFirstNameFa}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="First Name">
          {data?.consigneeFirstNameEn}
        </Descriptions.Item>
        <Descriptions.Item label="نام خانوادگی">
          {data?.consigneeLastNameFa}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Last Name">
          {data?.consigneeLastNameEn}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="شماره تماس">
          {data?.consigneePhoneNumber}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="آدرس">
          {data?.consigneeAddress}
        </Descriptions.Item>
      </Descriptions>
      <hr className={styles.divider} />
      <Descriptions
        title="مشخصات شخص مخاطب در بندر مقصد (Notify party)"
        bordered
      >
        <Descriptions.Item label="نام">
          {data?.notifyFirstNameFa}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="First Name">
          {data?.notifyFirstNameEn}
        </Descriptions.Item>
        <Descriptions.Item label="نام خانوادگی">
          {data?.notifyLastNameFa}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Last Name">
          {data?.notifyLastNameEn}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="شماره تماس">
          {data?.notifyPhoneNumber}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="آدرس">
          {data?.notifyAddress}
        </Descriptions.Item>
      </Descriptions>
      <hr className={styles.divider} />

      <Descriptions title="مشخصات شرکت حمل" bordered>
        <Descriptions.Item span={3} label="نام شرکت">
          {data?.inquires?.map((item) => item.supplier.user.name)}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="شماره تماس">
          {data?.inquires?.map((item) => item.supplier.user.mobileNo)}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="ایمیل">
          {data?.inquires?.map((item) => item.supplier.user.email)}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="آدرس">
          {data?.inquires?.map((item) => item.supplier.address)}
        </Descriptions.Item>

        <Descriptions.Item span={3} label="قیمت">
          <span style={{ padding: "8px" }}>
            {
              consigneeRelations.data.currency.find(
                (item) => item.id === data?.currencyId
              )?.generalName
            }
          </span>
          {data?.inquires?.map((item) => item.price)}
        </Descriptions.Item>
      </Descriptions>

      {data?.inquires && data?.inquires[0] && (
        <>
          <hr className={styles.divider} />
          <Descriptions title="مشخصات سفارش" bordered>
            <Descriptions.Item label="نوع ارز">
              {
                consigneeRelations.data.inqCurrency.find(
                  // @ts-ignore
                  (item) => item.id === data?.inquires[0]?.inqCurrencyId
                )?.generalName
              }
            </Descriptions.Item>
            <Descriptions.Item label="قیمت بر اساس">
              {
                consigneeRelations.data.priceType.find(
                  // @ts-ignore
                  (item) => item.id === data?.inquires[0]?.priceTypeId
                )?.generalName
              }
            </Descriptions.Item>
            <Descriptions.Item label="قیمت">
              {data?.inquires[0]?.price}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="اولین تاریخ بارگیری">
              {data?.inquires[0]?.firstLoadingDate
                ? moment(parseInt(data?.inquires[0]?.firstLoadingDate)).format(
                    "YYYY/MM/DD HH:mm"
                  )
                : ""}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="آخرین تاریخ بارگیری">
              {data?.inquires[0]?.lastLoadingDate
                ? moment(parseInt(data?.inquires[0]?.lastLoadingDate)).format(
                    "YYYY/MM/DD HH:mm"
                  )
                : ""}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="تاریخ تقریبی ترک مبدا">
              {data?.inquires[0]?.etd
                ? moment(parseInt(data?.inquires[0]?.etd)).format(
                    "YYYY/MM/DD HH:mm"
                  )
                : ""}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="تاریخ تقریبی رسیدن به مقصد">
              {data?.inquires[0]?.eta
                ? moment(parseInt(data?.inquires[0]?.eta)).format(
                    "YYYY/MM/DD HH:mm"
                  )
                : ""}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="توضیحات">
              {data?.inquires[0]?.comment}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
}

export default DocsModal;
