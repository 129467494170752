import { useState, useEffect } from "react";
import {
  Spin,
  Space,
  Button,
  Tag,
  Table,
  Modal,
  TablePaginationConfig,
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import ConfirmModal from "../../../components/ConfirmModal";
import Card from "../../../components/Card/Card";
import { deleteVoyage, getVessels, getVoyages } from "../../../redux/actions";
import { IState, IVoyage } from "../../../redux/types";
import ShowModal from "./Modals/ShowModal";
import VoyageModal from "./Modals/VoyageModal";

function Voyage() {
  const consigneeOrderList = useSelector(
    (state: IState) => state.consigneeOrderList
  );
  const voyages = useSelector((state: IState) => state.voyages);
  const vessels = useSelector((state: IState) => state.vessels);
  const voyageRelationsState = useSelector(
    (state: IState) => state.voyageRelations
  );
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState<[boolean, IVoyage | undefined]>([
    false,
    undefined,
  ]);
  const [confirmModal, setConfirmModal] = useState<
    [boolean, number | undefined]
  >([false, undefined]);
  const [addVoyageModal, setAddVoyageModal] = useState<
    [boolean, IVoyage | undefined]
  >([false, undefined]);

  useEffect(() => {
    dispatch(getVessels());
    dispatch(
      getVoyages({
        limit: 10,
        page: 1,
      })
    );
  }, []);

  function onChangeTable(pagination: TablePaginationConfig) {
    if (pagination.current && pagination.pageSize) {
      dispatch(
        getVoyages({
          page: pagination.current,
          limit: pagination.pageSize,
        })
      );
    }
  }

  function onShowSizeChange(current: number, size: number) {
    dispatch(
      getVoyages({
        limit: size,
        page: current,
      })
    );
  }

  const columns = [
    {
      title: "نام",
      dataIndex: "voyageName",
      key: "voyageName",
    },
    {
      title: "شماره سفر",
      dataIndex: "voyageNo",
      key: "voyageNo",
    },
    {
      title: "شناور",
      dataIndex: "vesselId",
      key: "vesselId",
      render: (id: number) => {
        return <Tag>{vessels.data.find((item) => item.id === id)?.name}</Tag>;
      },
    },
    {
      title: "مبدا",
      dataIndex: "dischargingPortId",
      key: "dischargingPortId",
      render: (id: number) => (
        <Tag>
          {
            voyageRelationsState.data.dischargingPort.find(
              (item) => item.id === id
            )?.locationName
          }
        </Tag>
      ),
    },
    {
      title: "مقصد",
      dataIndex: "dischagingPortId",
      key: "dischagingPortId",
      render: (id: number) => (
        <Tag>
          {
            voyageRelationsState.data.dischagingPort.find(
              (item) => item.id === id
            )?.locationName
          }
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, row: IVoyage) => {
        return (
          <Space size={20} className="alignEnd">
            <EyeOutlined
              onClick={() => {
                setShowModal([true, row]);
              }}
            />
            <EditOutlined
              onClick={() => {
                setAddVoyageModal([true, row]);
              }}
            />
            <DeleteOutlined
              className="deleteIcon"
              onClick={() => {
                setConfirmModal([true, row.id]);
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Card>
      <Spin spinning={consigneeOrderList.loading}>
        <div style={{ justifyContent: "flex-end", marginBottom: 20 }}>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              setAddVoyageModal([true, undefined]);
            }}
          >
            ثبت سفر
          </Button>
        </div>
        <Table
          rowKey="voyageName"
          scroll={{ x: true }}
          loading={false}
          onChange={onChangeTable}
          dataSource={voyages.data}
          columns={columns}
          pagination={{
            total: voyages.params.count,
            pageSize: voyages.params.limit,
            current: voyages.params.page,
            showTotal: (total: number) => `${total} مورد`,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            pageSizeOptions: ["10", "20", "50"],
            showTitle: false,
            locale: {
              items_per_page: "",
            },
          }}
        />
        <Modal
          title="سفر"
          visible={showModal[0]}
          onOk={() => setShowModal([false, undefined])}
          onCancel={() => setShowModal([false, undefined])}
          width={800}
          footer={false}
        >
          <ShowModal data={showModal[1]} />
        </Modal>
        <Modal
          title={addVoyageModal[1] ? "ویرایش سفر" : "ثبت سفر"}
          visible={addVoyageModal[0]}
          onOk={() => setAddVoyageModal([false, undefined])}
          onCancel={() => setAddVoyageModal([false, undefined])}
          width={800}
          footer={false}
        >
          <VoyageModal
            data={addVoyageModal[1]}
            closeModal={() => setAddVoyageModal([false, undefined])}
          />
        </Modal>
        <ConfirmModal
          onCancel={() => setConfirmModal([false, undefined])}
          onConfirm={() => {
            if (confirmModal[1]) {
              dispatch(
                deleteVoyage(confirmModal[1], () => {
                  setConfirmModal([false, undefined]);
                  dispatch(
                    getVoyages({
                      limit: voyages.params.limit,
                      page: voyages.params.page,
                    })
                  );
                  setConfirmModal([false, undefined]);
                })
              );
            }
          }}
          visible={confirmModal[0]}
        >
          <p style={{ textAlign: "center" }}>
            آیا مطمین هستید که این سفر حذف شود؟
          </p>
        </ConfirmModal>
      </Spin>
    </Card>
  );
}

export default Voyage;
