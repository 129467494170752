import { Form, Spin, Row, Col, Space, Button, Input } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Card from "../../../components/Card/Card";
import {
  addConsignee,
  consigneeRelations,
  editUser,
} from "../../../redux/actions";
import { IState } from "../../../redux/types";

import styles from "./Profile.module.scss";
const { TextArea } = Input;

function Profile() {
  const user = useSelector((state: IState) => state.user);
  const consignee = useSelector((state: IState) => state.consignee);
  const signin = useSelector((state: IState) => state.signin);
  console.log("signin:::", signin);

  const [password, setPassword] = useState<string>("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  return (
    <div className={styles.container}>
      <Card>
        <Spin spinning={consignee.loading}>
          <Form
            layout="vertical"
            initialValues={consignee.data}
            form={form2}
            onFinish={(data) => {
              dispatch(
                addConsignee(
                  consignee.data.idCode === "" ? "POST" : "PUT",
                  data,
                  () => {
                    if (consignee.data.idCode === "") {
                      dispatch(consigneeRelations(() => {}));
                    }
                  }
                )
              );
            }}
          >
            <div className={styles.detailContainer}>
              <Row gutter={[30, 20]}>
                {/* <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="name"
                    label="نام"
                    rules={[{ required: true, message: 'مقدار را وارد کنید!' }]}
                  >
                    <Input/>
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    label="کد ملی/شناسه ملی"
                    name="idCode"
                    rules={[
                      { required: true, message: "مقدار را وارد کنید!" },
                      // { pattern: /^\d{10}$/, message: 'مقدار صحیح نیست!' },
                    ]}
                  >
                    <Input disabled={consignee.data.idCode !== ""} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item label="کد اقتصادی" name="economicCode">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="phone" label="شماره تلفن ثابت">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="celPhone" label="شماره تلفن همراه">
                    <Input disabled defaultValue={signin.number} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="fax" label="فکس">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="contactName" label="نام مخاطب">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="postalCode"
                    label="کد پستی"
                    rules={
                      [
                        // { pattern: /^{10}$/, message: 'مقدار صحیح نیست!' },
                      ]
                    }
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="address" label="آدرس">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="code" label="کد معرف">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Row>
              <Col span={24} className="alignEnd">
                <Space size={16}>
                  <Button htmlType="submit" size="large" type="primary">
                    ذخیره
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
      <Card>
        <Spin spinning={user.loading}>
          <Form
            layout="vertical"
            initialValues={user.data}
            form={form}
            onFinish={(data) => {
              if (data.password) {
                if (data.password === password) {
                  dispatch(editUser(data, () => {}));
                } else {
                  toast.error("تکرار پسورد نادرست است!");
                }
              } else {
                dispatch(editUser(data, () => {}));
              }
            }}
          >
            <div className={styles.detailContainer}>
              <Row gutter={[30, 20]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="name"
                    label="نام شخص حقیقی/حقوقی"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    label="نام کاربری"
                    name="username"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    label="ایمیل"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "ایمیل را به درستی وارد کنید!",
                      },
                      { required: true, message: "مقدار را وارد کنید!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="password"
                    label="رمز عبور"
                    rules={[
                      {
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                        message:
                          "رمزعبور حداقل 8 کاراکتر شامل عدد، حروف کوچک و بزرگ و کاراکتر خاص ",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="Cpassword"
                    label="تکرار رمز عبور"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("تکرار رمز عبور درست نمیباشد!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Row>
              <Col span={24} className="alignEnd">
                <Space size={16}>
                  <Button htmlType="submit" size="large" type="primary">
                    ذخیره
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </div>
  );
}

export default Profile;
