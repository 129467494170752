import DEFAULT_AVATAR from '../../assets/images/default-avatar.jpg'

import styles from './Avatar.module.scss'

type Props = {
  src?: string | null | undefined;
  width: number;
}

function Avatar({ src, width }: Props) {
  if (src) {
    return <img className={styles.avatar} style={{ width: width, height: width}} src={src} alt="user avatar" />
  }

  return <img className={styles.avatar} style={{ width: width, height: width}} src={DEFAULT_AVATAR} alt="user default avatar" />
}

export default Avatar
