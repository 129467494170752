import { ReactNode } from 'react'
import { Card, Form, Input, Button, Space, Spin } from 'antd'
import { Rule } from "rc-field-form/lib/interface";

import styles from './AuthBox.module.scss'

interface IAction {
  label: string,
  onClick: (values: any) => void | undefined,
  type: "default" | "link" | "text" | "ghost" | "primary" | "dashed" | undefined,
  main: boolean,
}

interface IField {
  name: string,
  label: string,
  type: "number" | "text" | "hidden" | "color" | "button" | "time" | "image" | "tel" | "url" | "email" | "search" | "date" | "submit" | "reset" | "checkbox" | "datetime-local" | "file" | "week" | "password" | undefined,
  rules?: Rule[], 
}

interface IProps {
  title?: string,
  description?: string,
  children: ReactNode,
  form: IField[],
  actions: IAction[],
  footer?: ReactNode,
  loading: boolean,
}

function Box({ title, description, form, children, actions, footer, loading}: IProps) {
  const [formAnt] = Form.useForm();

  return (
    <Card title={title || undefined} className={styles.container}>
      <Spin spinning={loading}>
        {description && (
          <div>
            {description}
          </div>
        )}
        {children}
        <Form
          form={formAnt}
          name="basic"
          initialValues={{ remember: true }}
          style={{ marginTop: 30, marginBottom: 30 }}
          // @ts-ignore
          onFinish={actions.find((item) => item.main)?.onClick}
          // onFinishFailed={onFinishFailed}
        >
          {form.map((item) => (
            <Form.Item
              // label={item.label}
              name={item.name}
              rules={item.rules}
            >
              {item.type === 'password' ? <Input.Password placeholder={item.label} /> : <Input type={item.type} placeholder={item.label} />}
            </Form.Item>
          ))}
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            {actions.map((item) => (
              <Button type={item.type} htmlType={item.main ? 'submit' : undefined} onClick={() => {
                if (!item.main) {
                  item.onClick('')
                }
              }}>{item.label}</Button>
            ))}
          </Space>
        </Form>
        {footer && (
          footer
        )}
      </Spin>
    </Card>
  )
}

export default Box
