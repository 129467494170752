import { Button } from 'antd'
import { useHistory } from "react-router-dom"

import styles from './Layout.module.scss'
import LOGO from '../../assets/images/logo.png'

function Header() {
  let history = useHistory()

  return (
    <div className={styles.header}>
      <div className={styles.actionsContainer}>
        <Button size="large" shape="round" type="primary" className={`${styles.firstAction} ${styles.action}`} onClick={() => {
          history.push('/signin', { userType: 1})
        }}>
          صاحبان کالا 
        </Button>
        <Button size="large" shape="round" type="primary" className={styles.action} onClick={() => {
          history.push('/signin', { userType: 2})
        }}>
          شرکت های حمل بار
        </Button>
      </div>

      <div onClick={() => history.push("/")} className={styles.logoContainer} >
        <img className={styles.logo} src={LOGO} alt="logo" />
      </div>
    </div>
  )
}

export default Header
