import { Descriptions } from 'antd'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { IState, IVoyage } from '../../../../redux/types'

interface IProps {
  data: IVoyage | undefined
}

function ShowModal({ data }: IProps) {
  const voyageRelations = useSelector((state: IState) => state.voyageRelations)
  const vessels = useSelector((state: IState) => state.vessels)

  return (
    <div>
      <Descriptions bordered>
        <Descriptions.Item label="نام سفر">{data?.voyageName}</Descriptions.Item>
        <Descriptions.Item span={2} label="مبدا">{
          voyageRelations.data.dischagingPort.find(item => item.id === data?.dischagingPortId)?.locationName
        }</Descriptions.Item>
        <Descriptions.Item label="شماره سفر">{data?.voyageNo}</Descriptions.Item>
        <Descriptions.Item span={2} label="مقصد">{
          voyageRelations.data.dischargingPort.find(item => item.id === data?.dischargingPortId)?.locationName
        }</Descriptions.Item>
        <Descriptions.Item span={2} label="نام شناور">{
          vessels.data.find(item => item.id === data?.vesselId)?.name
        }</Descriptions.Item>
        <Descriptions.Item label="تاریخ سفر">{data?.voyageDate ? moment(parseInt(data?.voyageDate)).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
        <Descriptions.Item label="تاریخ تقریبی آمادگی بارگیری">{data?.etl ? moment(parseInt(data?.etl)).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
        <Descriptions.Item label="تاریخ تقریبی ترک مبدا">{data?.etd ? moment(parseInt(data?.etd)).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
        <Descriptions.Item label="تاریخ تقریبی رسیدن به مقصد">{data?.eta ? moment(parseInt(data?.eta)).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default ShowModal
