import { ReactNode } from 'react'

import Avatar from './Avatar'

import styles from './Avatar.module.scss'

type Props = {
  src?: string | null | undefined;
  name: string | ReactNode;
  width?: number;
  onClick?: () => void;
}

function AvatarName({ src, name, width = 30, onClick }: Props) {
  return (
    <div onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default' }} className={styles.container}>
      <Avatar width={width} src={src} />
      <span>&nbsp;&nbsp;{name}</span>
    </div>
  )
}

export default AvatarName
