import React, { ReactNode, useState, useEffect } from "react";
import { Menu, Button } from "antd";
import {
  HomeOutlined,
  UserOutlined,
  FileAddOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CaretDownOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
// import { RiShipLine } from "react-icons/ri";
// import { GiCargoShip } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { signout } from "../../redux/actions";
import AvatarName from "../../components/Avatar/AvatarName";
import Footer from "./Footer";
import { IState } from "../../redux/types";

import LOGO from "../../assets/images/logo.png";
import styles from "./Layout.module.scss";

interface IProps {
  userType: 1 | 2;
  children: ReactNode;
}

const { SubMenu } = Menu;

function Layout({ userType, children }: IProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: IState) => state.user.data);

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 992) {
      setCollapsed(true);
    }
  }, []);

  return (
    <div className={styles.main}>
      <div
        className={styles.container}
        onClick={() => {
          if (window.innerWidth < 992 && !collapsed) {
            setCollapsed(true);
          }
        }}
      >
        <div className={styles.header}>
          <Menu
            className={`${styles.menu} ${styles.profileMenu}`}
            mode="horizontal"
          >
            <SubMenu
              key="profile"
              title={
                <span className={styles.profile}>
                  <AvatarName name={user?.name || user?.phone} />
                  &nbsp; <CaretDownOutlined />
                </span>
              }
            >
              <Menu.Item
                onClick={() => {
                  history.push("/panel/profile");
                }}
                key="setting:1"
              >
                <UserOutlined style={{ marginRight: "10px" }} />
                پروفایل
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  dispatch(
                    signout(() => {
                      history.push("/");
                    })
                  );
                }}
                key="setting:2"
              >
                <LogoutOutlined style={{ marginRight: "10px" }} />
                خروج
              </Menu.Item>
            </SubMenu>
          </Menu>
          <h3 style={{ color: "#eda64b", fontWeight: "bold" }}>
            سامانه جامع حمل و نقل و لجستیک کالا
          </h3>

          <Button onClick={() => setCollapsed(!collapsed)}>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
            )}
          </Button>
        </div>
        <div className={styles.content}>{children}</div>
        <Footer />
      </div>
      <div className={`${styles.side} ${!collapsed ? styles.collapsed : ""}`}>
        <Menu
          defaultSelectedKeys={["home"]}
          // defaultOpenKeys={['sub1']}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          style={{ height: "100%", position: "relative" }}
          onClick={() => {
            if (window.innerWidth < 992 && !collapsed) {
              setCollapsed(true);
            }
          }}
        >
          <div className={styles.logoContainer} style={{ height: "140px" }}>
            <img className={styles.logo} src={LOGO} alt="logo" />
            {/* <h2 className={styles.logoType}>BioPass</h2> */}
          </div>

          {userType === 2 ? (
            <>
              <div
                className={styles.logoContainer}
                style={{
                  height: "140px",
                  position: "absolute",
                  bottom: "0",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <p style={{ textAlign: "center", width: "100%" }}>
                  شماره تماس پشتیبانی ۲۸۴۲۳۲۷۶{" "}
                </p>
              </div>
              <Menu.Item
                key="home"
                onClick={() => {
                  history.push("/panel/home");
                }}
                icon={<HomeOutlined />}
              >
                خانه
              </Menu.Item>
              <Menu.Item
                key="profile"
                onClick={() => {
                  history.push("/panel/profile");
                }}
                icon={<UserOutlined />}
              >
                پروفایل من
              </Menu.Item>
              <Menu.Item
                key="vessel"
                onClick={() => {
                  history.push("/panel/vessel");
                }}
                icon={<HomeOutlined />}
              >
                تعریف شناور
              </Menu.Item>
              <Menu.Item
                key="voyage"
                onClick={() => {
                  history.push("/panel/voyage");
                }}
                icon={<HomeOutlined />}
              >
                تخصیص سفر
              </Menu.Item>
              {/* <Menu.Item key="cardboard" onClick={() => {
                history.push('/panel/cardboard')
              }} icon={<IdcardOutlined />}>
                کارتابل
              </Menu.Item> */}
            </>
          ) : (
            <>
              <div
                className={styles.logoContainer}
                style={{
                  height: "140px",
                  position: "absolute",
                  bottom: "0",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <p style={{ textAlign: "center", width: "100%" }}>
                  شماره تماس پشتیبانی ۲۸۴۲۳۲۷۶{" "}
                </p>
              </div>
              <Menu.Item
                key="home"
                onClick={() => {
                  history.push("/panel/home");
                }}
                icon={<HomeOutlined />}
              >
                خانه
              </Menu.Item>
              <Menu.Item
                key="profile"
                onClick={() => {
                  history.push("/panel/profile");
                }}
                icon={<UserOutlined />}
              >
                پروفایل من
              </Menu.Item>
              <Menu.Item
                key="addOrder"
                onClick={() => {
                  history.push("/panel/add-order");
                }}
                icon={<FileAddOutlined />}
              >
                استعلام کرایه حمل دریایی
              </Menu.Item>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}

export default Layout;
