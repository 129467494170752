import { useState, useEffect } from "react";
import {
  Form,
  Spin,
  Row,
  Col,
  Space,
  Divider,
  Button,
  Input,
  Select,
  Tag,
  Tabs,
  Table,
  Modal,
  Collapse,
} from "antd";
import { FileTextOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ConfirmModal from "../../../components/ConfirmModal";
import Card from "../../../components/Card/Card";
import { getConsigneeOrderList, getPaymentOrder } from "../../../redux/actions";
import { IState, IOrder, IInvoice } from "../../../redux/types";
import DocsModal from "./Modals/DocsModal";
import ShowModal from "./Modals/ShowModal";
import InvoiceModal from "./Modals/InvoiceModal";

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

function Home() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const consigneeRelations = useSelector(
    (state: IState) => state.consigneeRelations
  );
  const consigneeOrderList = useSelector(
    (state: IState) => state.consigneeOrderList
  );
  const getPaymentState = useSelector((state: IState) => state.getPaymentOrder);

  const [docsModal, setDocsModal] = useState(false);
  const [docsModalCarryOrderID, setDocsModalCarryOrderID] = useState(0);
  const [orderModal, setOrderModal] = useState<[boolean, IOrder | undefined]>([
    false,
    undefined,
  ]);
  const [invoiceModal, setInvoiceModal] = useState<
    [boolean, IInvoice | undefined]
  >([false, undefined]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<undefined | number>(
    undefined
  );

  useEffect(() => {
    console.log("docsModal::", docsModal);
  }, [docsModal]);

  function switchActiveKey(key: string) {
    switch (key) {
      case "1":
        return "haveInquire";
      case "2":
        return "confirm";
      case "3":
        return "done";
      case "4":
        return "close";
      default:
        break;
    }
  }

  useEffect(() => {
    dispatch(
      getConsigneeOrderList({
        searchFilter: "haveInquire",
        limit: consigneeOrderList.params.limit,
        page: 1,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onShowSizeChange(current: number, size: number) {
    dispatch(
      getConsigneeOrderList({
        ...consigneeOrderList.params,
        limit: size,
        page: current,
      })
    );
  }

  function tabsHandler(activeKey: string) {
    dispatch(
      getConsigneeOrderList({
        searchFilter: switchActiveKey(activeKey),
        limit: consigneeOrderList.params.limit,
        page: 1,
      })
    );
  }

  const baseColumns = [
    {
      title: "شماره درخواست",
      dataIndex: "orderLongNo",
      key: "orderLongNo",
    },
    {
      title: "تاریخ و ساعت",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (td: any) => moment(parseInt(td)).format("YYYY/MM/DD HH:mm"),
    },
    {
      title: "مبدا",
      dataIndex: "loadingPortId",
      key: "loadingPortId",
      render: (td: any) => (
        <Tag>
          {
            consigneeRelations.data.loadingPort.find((item) => item.id === td)
              ?.locationName
          }
        </Tag>
      ),
    },
    {
      title: "مقصد",
      dataIndex: "dischargingPortId",
      key: "dischargingPortId",
      render: (td: any) => (
        <Tag>
          {
            consigneeRelations.data.dischargingPort.find(
              (item) => item.id === td
            )?.locationName
          }
        </Tag>
      ),
    },
    // {
    //   title: 'نوع کالا',
    //   dataIndex: 'commudityID',
    //   key: 'commudityID',
    // },
    {
      title: "شرح کالا",
      dataIndex: "goodDescription",
      key: "goodDescription",
    },
    {
      title: "وزن خالص",
      dataIndex: "netWeight",
      key: "netWeight",
    },
  ];

  const completeColumns = [
    {
      title: "شرکت حمل و نقل و لجستیک",
      dataIndex: "supplier",
      key: "supplier",
      render: (text: any, row: any) => {
        return text?.inquiries[0]?.supplier?.name;
      },
    },
    {
      title: "اسناد",
      dataIndex: "docs",
      key: "docs",
      render: (text: any, row: any) => {
        return (
          <FileTextOutlined
            onClick={() => {
              setDocsModal(true);
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, row: IOrder) => {
        return (
          <Space size={20}>
            <EyeOutlined
              onClick={() => {
                setOrderModal([true, row]);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const inProgressColumns = [
    {
      title: "شرکت حمل و نقل و لجستیک",
      dataIndex: "supplier",
      key: "supplier",
      render: (text: any, row: any) => {
        return row?.inquires?.map((item: any) => {
          console.log("item::", item.supplier.user.name);
          return item.supplier.user.name;
        });
      },
    },
    {
      title: "اسناد",
      dataIndex: "docs",
      key: "docs",
      render: (text: any, row: any) => {
        return (
          <FileTextOutlined
            onClick={() => {
              setDocsModalCarryOrderID(row.id);
              setDocsModal(true);
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, row: IOrder) => {
        return (
          <Space size={20} className="alignEnd">
            <EyeOutlined
              onClick={() => {
                setOrderModal([true, row]);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const closedColumns = [
    {
      title: "شرکت حمل و نقل و لجستیک",
      dataIndex: "supplier",
      key: "supplier",
      render: (text: any, row: any) => {
        return text?.inquiries[0]?.supplier?.name;
      },
    },
    {
      title: "اسناد",
      dataIndex: "docs",
      key: "docs",
      render: (text: any, row: any) => {
        console.log("text:::", text);

        return (
          <FileTextOutlined
            onClick={() => {
              setDocsModal(true);
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, row: IOrder) => {
        return (
          <Space size={20}>
            <EyeOutlined
              onClick={() => {
                setOrderModal([true, row]);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const freshColumns = [
    {
      title: "استعلام",
      dataIndex: "inquires",
      key: "inquires",
      render: (text: any, row: any) => {
        const filteredArray = text?.filter((item: any) => {
          return item.inquiryStatus === 1;
        });
        return (
          <Button
            type="primary"
            size="small"
            disabled={filteredArray.length <= 0}
            onClick={() => {
              setSelectedOrder(row.id);
              history.push("/panel/home/inquiries", { orderId: row.id });
            }}
          >
            {filteredArray.length}
          </Button>
        );
      },
    },
  ];

  return (
    <Card>
      <div>
        <Collapse defaultActiveKey="0">
          <Panel header="جست و جوی پیشرفته" key="1">
            <Form
              layout={window.innerWidth < 700 ? "vertical" : "horizontal"}
              initialValues={consigneeOrderList.params}
              form={form}
              onFinish={(data) => {
                dispatch(
                  getConsigneeOrderList({
                    ...data,
                    dateEnd: data.dateEnd
                      ? moment(data.dateEnd).format("x")
                      : "",
                    dateStart: data.dateStart
                      ? moment(data.dateStart).format("x")
                      : "",
                    page: 1,
                    limit: consigneeOrderList.params.limit,
                  })
                );
              }}
            >
              <Row gutter={[30, 0]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item label="جستجو" name="searchName">
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}></Col>
                <Col xs={24} sm={24} md={24} lg={9}>
                  <Form.Item label="تاریخ شروع" name="dateStart">
                    <Input allowClear type="datetime-local" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9}>
                  <Form.Item name="dateEnd" label="تاریخ اتمام">
                    <Input allowClear type="datetime-local" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9}>
                  <Form.Item label="مبدا" name="loadingPortId">
                    <Select allowClear showSearch optionFilterProp="children">
                      {consigneeRelations.data.loadingPort.map((item: any) => (
                        <Option value={item.id}>{item.locationName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9}>
                  <Form.Item name="dischargingPortId" label="مقصد">
                    <Select allowClear showSearch optionFilterProp="children">
                      {consigneeRelations.data.dischargingPort.map(
                        (item: any) => (
                          <Option value={item.id}>{item.locationName}</Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  className="alignEnd"
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Form.Item>
                    <Space size={16}>
                      <Button htmlType="submit" type="primary">
                        جستجو
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Panel>
        </Collapse>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          padding: "16px 0 0 0 ",
          flexDirection: "row-reverse",
          fontSize: "16px",
        }}
      >
        <p>ليست استعلام ها</p>
      </div>
      <Spin spinning={consigneeOrderList.loading || getPaymentState.loading}>
        <Tabs
          defaultActiveKey="1"
          onChange={tabsHandler}
          tabBarExtraContent={
            <Button
              type="primary"
              size="large"
              onClick={() => {
                history.push("/panel/add-order");
              }}
            >
              استعلام کرایه حمل دریایی
            </Button>
          }
        >
          <TabPane tab="در انتظار پاسخ" key="1">
            <Table
              rowKey="orderLongNo"
              scroll={{ x: true }}
              loading={false}
              dataSource={consigneeOrderList.data.orderListHaveInquiries}
              columns={[...baseColumns, ...freshColumns]}
              pagination={{
                total: consigneeOrderList.data.numberOfOrderListHaveInquiries,
                pageSize: consigneeOrderList.params.limit,
                current: consigneeOrderList.params.page + 1,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                pageSizeOptions: ["10", "20", "50"],
                showTitle: false,
                locale: {
                  items_per_page: "",
                },
              }}
            />
          </TabPane>
          <TabPane tab="تاييد شده" key="2">
            <Table
              rowKey="orderLongNo"
              scroll={{ x: true }}
              loading={false}
              dataSource={consigneeOrderList.data.orderListConfirm}
              columns={[...baseColumns, ...inProgressColumns]}
              pagination={{
                total: 1,
                pageSize: 10,
                current: 1,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                pageSizeOptions: ["10", "20", "50"],
                showTitle: false,
                locale: {
                  items_per_page: "",
                },
              }}
            />
          </TabPane>
        </Tabs>
        <Modal
          title="سفارش"
          visible={orderModal[0]}
          onOk={() => setOrderModal([false, undefined])}
          onCancel={() => setOrderModal([false, undefined])}
          width={800}
          footer={false}
        >
          <ShowModal
            data={orderModal[1]}
            closeModal={() => setOrderModal([false, undefined])}
          />
        </Modal>
        <DocsModal
          open={docsModal}
          handleClose={() => setDocsModal(false)}
          docsModalCarryOrderID={docsModalCarryOrderID}
        />
        <Modal
          title="پرداخت"
          visible={invoiceModal[0]}
          onOk={() => setInvoiceModal([false, undefined])}
          onCancel={() => setInvoiceModal([false, undefined])}
          width={800}
          footer={false}
        >
          <InvoiceModal
            orderId={selectedOrder}
            payment={invoiceModal[1]}
            closeModal={() => setInvoiceModal([false, undefined])}
          />
        </Modal>
        <ConfirmModal
          onCancel={() => setConfirmModal(false)}
          onConfirm={() => {
            setConfirmModal(false);
            // dispatch(restoreUser(userData.data.userId))
          }}
          visible={confirmModal}
        >
          <p style={{ textAlign: "center" }}>
            آیا مطمین هستید که این سفارش لغو شود؟
          </p>
        </ConfirmModal>
      </Spin>
    </Card>
  );
}

export default Home;
