import { useState, useLayoutEffect } from "react";
import {
  Alert,
  Row,
  Descriptions,
  Col,
  Rate,
  Button,
  Divider,
  Space,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Card from "../../../components/Card/Card";
import { IInquiry, IState } from "../../../redux/types";
import { acceptInquiry } from "../../../redux/actions";

import LOGO from "../../../assets/images/logo.png";
import styles from "./index.module.scss";

function Inquiry() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const orderInquiries = useSelector((state: IState) => state.orderInquiries);
  const consigneeRelations = useSelector(
    (state: IState) => state.consigneeRelations
  );

  const [selectedInquiry, setSelectedInquiry] = useState<IInquiry | undefined>(
    undefined
  );

  console.log("selectedInquiry?.eta", location.state);

  useLayoutEffect(() => {
    setSelectedInquiry(
      orderInquiries.data.inquires?.find(
        (item) => item.id === location.state?.inquiryId
      )
    );
  }, []);

  return (
    <Card>
      <Row gutter={[30, 30]} style={{ position: "relative" }}>
        <p
          style={{
            position: "absolute",
            zIndex: 10,
            left: 0,
            top: "-20px",
            border: "1px solid #eda64b",
            padding: "16px",
            borderRadius: "8px",
            fontWeight: "bold",
          }}
        >
          کرایه حمل {selectedInquiry?.price}{" "}
          {
            consigneeRelations.data.inqCurrency.find(
              (el) => el.id === selectedInquiry?.inqCurrencyId
            )?.generalName
          }{" "}
          به ازای هر{" "}
          {
            consigneeRelations.data.priceType.find(
              (el) => el.id === selectedInquiry?.priceTypeId
            )?.generalName
          }
        </p>
        <Col xs={24} sm={24} md={24} lg={6}>
          <div className={styles.introCompanyContainer}>
            <img src={LOGO} alt="logo" />
            {/* <h2>{item.name}</h2> */}
            <h2> </h2>
            {/* <Rate disabled defaultValue={0} value={item.rate} /> */}
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={18}>
          <Descriptions title="مشخصات حمل" bordered>
            <Descriptions.Item
              span={3}
              label={`قیمت پیشنهادی به ازای هر ${
                consigneeRelations.data.priceType.find(
                  (el) => el.id === selectedInquiry?.priceTypeId
                )?.generalName
              }`}
            >
              {selectedInquiry?.price}
            </Descriptions.Item>
            {/* <Descriptions.Item label="قیمت کل">{selectedInquiry?.}</Descriptions.Item> */}
            <Descriptions.Item span={3} label="امکان بارگیری از تاریخ">
              {selectedInquiry?.firstLoadingDate
                ? moment(parseInt(selectedInquiry?.firstLoadingDate)).format(
                    "YYYY/MM/DD HH:mm"
                  )
                : ""}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="امکان بارگیری تا تاریخ">
              {selectedInquiry?.lastLoadingDate
                ? moment(parseInt(selectedInquiry?.lastLoadingDate)).format(
                    "YYYY/MM/DD HH:mm"
                  )
                : ""}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="تاریخ و ساعت ترک بندر">
              {selectedInquiry?.etd
                ? moment(parseInt(selectedInquiry?.etd)).format(
                    "YYYY/MM/DD HH:mm"
                  )
                : ""}
            </Descriptions.Item>
            <Descriptions.Item
              span={3}
              label="تاریخ و ساعت رسیدن به  بندر مقصد"
            >
              {selectedInquiry?.eta
                ? moment(parseInt(selectedInquiry?.eta)).format(
                    "YYYY/MM/DD HH:mm"
                  )
                : ""}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="شماره تماس">
              {selectedInquiry?.supplier?.phone
                ? selectedInquiry?.supplier?.phone
                : ""}
            </Descriptions.Item>

            {/* <Descriptions.Item label="نوع شناور">{selectedInquiry?.}
            </Descriptions.Item> */}
          </Descriptions>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Descriptions title="مشخصات کالا" bordered>
          <Descriptions.Item label="تعداد کالا">
            {orderInquiries.data.qty}
          </Descriptions.Item>
          <Descriptions.Item label="نوع بسته بندی">
            {
              consigneeRelations.data.packageType.find(
                (item) => item.id === orderInquiries.data.packageTypeId
              )?.generalName
            }
          </Descriptions.Item>
          <Descriptions.Item label="نوع کالای خطرناک">
            {
              consigneeRelations.data.dgCode.find(
                (item) => item.id === orderInquiries.data.dGCodeId
              )?.generalName
              // orderInquiries.data.dGCodeId
            }
          </Descriptions.Item>
          <Descriptions.Item label="وزن خالص">
            {orderInquiries.data.netWeight}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="مبدا">
            {
              consigneeRelations.data.loadingPort.find(
                (item) => item.id === orderInquiries.data.loadingPortId
              )?.locationName
            }
          </Descriptions.Item>
          <Descriptions.Item label="وزن ناخالص">
            {orderInquiries.data.grossWeight}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="مقصد">
            {
              consigneeRelations.data.dischargingPort.find(
                (item) => item.id === orderInquiries.data.dischargingPortId
              )?.locationName
            }
          </Descriptions.Item>
          <Descriptions.Item label="ارزش گمرکی کالا">
            {orderInquiries.data.customValue}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="واحد ارز">
            {
              consigneeRelations.data.currency.find(
                (item) => item.id === orderInquiries.data.currencyId
              )?.generalName
            }
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="زمان تقریبی مورد نظر جهت تحویل بار به منظور بارگیری"
          >
            {orderInquiries.data.etl
              ? moment(parseInt(orderInquiries.data.etl)).format(
                  "YYYY/MM/DD HH:mm"
                )
              : ""}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="شرح کالا">
            {orderInquiries.data.goodDescription}
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Row style={{ marginTop: 30 }}>
        <Col>
          <Alert
            message="لطفا در صورتیکه پیشنهاد ارایه شده مورد تایید شما می باشد با انتخاب دکمه ارسال تایید خود را اعلام بفرمایید تا درخواست برای شرکت حمل ارسال گردد."
            type="warning"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginTop: 30 }} className="alignEnd">
          <Space size={16}>
            <Button
              htmlType="submit"
              size="large"
              onClick={() => {
                history.push("/panel/home/inquiries", {
                  orderId: location.state.orderId,
                });
              }}
            >
              بازگشت
            </Button>
            <Button
              htmlType="submit"
              size="large"
              type="primary"
              onClick={() => {
                dispatch(
                  acceptInquiry(
                    location.state?.inquiryId,
                    location.state?.orderId,
                    () => {
                      history.push("/panel/home");
                    }
                  )
                );
              }}
            >
              تایید
            </Button>
          </Space>
        </Col>
      </Row>
    </Card>
  );
}

export default Inquiry;
