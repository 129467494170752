import { ReactNode } from 'react'

import AppHeader from './Header';
import AppFooter from './Footer';

import styles from './Layout.module.scss';

interface IProps {
  children: ReactNode;
}

function AppLayout({ children }: IProps) {
  return (
    <div className={styles.main}>
      <AppHeader />
      <div className={styles.content}>{children}</div>
      <AppFooter />
    </div>
  )
}

export default AppLayout
