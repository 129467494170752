import { useEffect } from "react";
import { Spin, Form, Input, Button, Row, Col, Space, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { addVoyage, getVoyages } from "../../../../redux/actions";
import { IState, IVoyage } from "../../../../redux/types";

import styles from "../Vessel.module.scss";

interface IProps {
  data: IVoyage | undefined;
  closeModal: () => void;
}

const { Option } = Select;

function InvoiceModal({ data, closeModal }: IProps) {
  const addVoyageState = useSelector((state: IState) => state.addVoyage);
  const vesselsState = useSelector((state: IState) => state.vessels);
  const voyagesState = useSelector((state: IState) => state.voyages);
  const voyageRelations = useSelector((state: IState) => state.voyageRelations);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [data, closeModal]);

  return (
    <div>
      <Spin spinning={addVoyageState.loading}>
        <Form
          layout="vertical"
          initialValues={{
            ...data,
            voyageDate: data?.voyageDate
              ? new Date(parseInt(data?.voyageDate)).toISOString()?.slice(0, -1)
              : "",
            etd: data?.etd
              ? new Date(parseInt(data?.etd)).toISOString()?.slice(0, -1)
              : "",
            etl: data?.etl
              ? new Date(parseInt(data?.etl)).toISOString()?.slice(0, -1)
              : "",
            eta: data?.eta
              ? new Date(parseInt(data?.eta)).toISOString()?.slice(0, -1)
              : "",
          }}
          form={form}
          onFinish={(info) => {
            const vessel = vesselsState.data.find(
              (item) => item.id === info.vesselId
            );
            dispatch(
              addVoyage(
                data ? "PUT" : "POST",
                {
                  ...info,
                  voyageName: `${info.voyageNo}-${vessel?.name}`,
                  voyageDate: info.voyageDate
                    ? moment(info.voyageDate).format("x")
                    : "",
                  etl: info.etl ? moment(info.etl).format("x") : "",
                  etd: info.etd ? moment(info.etd).format("x") : "",
                  eta: info.eta ? moment(info.eta).format("x") : "",
                },
                () => {
                  form.resetFields();
                  closeModal();
                  dispatch(
                    getVoyages({
                      limit: voyagesState.params.limit,
                      page: voyagesState.params.page,
                    })
                  );
                },
                data?.id
              )
            );
          }}
        >
          <div className={styles.detailContainer}>
            <Row gutter={[30, 10]}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="vesselId"
                  label="نام شناور"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Select allowClear showSearch optionFilterProp="children">
                    {vesselsState.data.map((item: any) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label="شماره سفر"
                  name="voyageNo"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="dischagingPortId"
                  label="مبدا"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Select allowClear showSearch optionFilterProp="children">
                    {voyageRelations.data.dischagingPort.map((item: any) => (
                      <Option value={item.id}>{item.locationName}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="dischargingPortId"
                  label="مقصد"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Select allowClear showSearch optionFilterProp="children">
                    {voyageRelations.data.dischargingPort.map((item: any) => (
                      <Option value={item.id}>{item.locationName}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label="نام سفر"
                  name="voyageName"
                  rules={[{ required: true, message: 'مقدار را وارد کنید!' }]}
                >
                  <Input disabled  />
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="voyageDate"
                  label="تاریخ سفر"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Input allowClear type="datetime-local" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="etd" label="تاریخ تقریبی ترک مبدا">
                  <Input allowClear type="datetime-local" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="eta" label="تاریخ تقریبی رسیدن به مقصد">
                  <Input allowClear type="datetime-local" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="etl" label="تاریخ تقریبی آمادگی بارگیری">
                  <Input allowClear type="datetime-local" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24} className="alignEnd">
              <Space size={16}>
                <Button htmlType="submit" type="primary">
                  ذخیره
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}

export default InvoiceModal;
