import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { IState } from "../../../../redux/types";
import { deletetDoc, docDownload } from "../../../../redux/actions";
import classes from "./DocsTable.module.scss";
// import UploadFile from "../../../../components/UploadFile/UploadFile";

export default function DocsTable({
  docsModalCarryOrderID,
  handleUpload,
}: any) {
  // allDocTypes: {
  //   loading: false,
  //   data: null,
  // },
  // carryOrderDocTypes: {
  //   loading: false,
  //   data: null,
  // },

  const dispatch = useDispatch();
  const allDocTypes = useSelector((state: IState) => state.allDocTypes);
  const carryOrderDocTypes = useSelector(
    (state: IState) => state.carryOrderDocTypes
  );

  const [tableData, setTableData] = React.useState<{
    [key: number]: any;
  }>({});

  React.useEffect(() => {
    let newTempData: {
      [key: number]: any;
    } = {};
    if (!allDocTypes.loading) {
      // newTempData = Object.fromEntries(
      //   documents.allDocTypes?.forEach((item: any) => [item.id, { ...item }])
      // );
      allDocTypes.data?.forEach((item: any) => {
        const key = item.id as number;
        newTempData[key] = {
          ...item,
          rowId: item.id,
        };
      });
      if (newTempData) {
        if (carryOrderDocTypes.data) {
          carryOrderDocTypes.data?.forEach((item: any) => {
            const key = item.docTypesId as number;
            newTempData[key] = { ...newTempData[key], ...item };
          });
        }
      }
      // console.log(newTempData.name);
    }
    setTableData(newTempData);
  }, [carryOrderDocTypes, allDocTypes]);

  React.useEffect(() => {
    // console.log("tableData:::", tableData);
  }, [tableData]);

  const handleDownloadFile = (path: string) => {
    docDownload(path, docsModalCarryOrderID);
  };

  const handleDeleteFile = (id: number) => {
    dispatch(deletetDoc(id));
  };

  return (
    <>
      <Grid
        container
        style={{ width: "550px", direction: "rtl", marginBottom: "16px" }}
      >
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "16px",
            textAlign: "center",
            fontFamily: "samim",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          اسناد
        </Grid>
        <Grid item className={classes.myGrid}>
          نام
        </Grid>
        <Grid item className={classes.myGrid}>
          دانلود فایل
        </Grid>
        <Grid item className={classes.myGrid}>
          دانلود فایل خام
        </Grid>
        <Grid item className={classes.myGrid}>
          آپلود فایل
        </Grid>
        <Grid item className={classes.myGrid}>
          حدف فایل
        </Grid>
      </Grid>

      <Grid container sx={{ maxHeight: "400px", overflowY: "scroll" }}>
        {Object.keys(tableData).map((item: any, idx: any) => {
          const tempData = tableData[item];
          console.log("tempLL::", tempData);

          const nameStr = JSON.parse(tempData.name.replace(/\\/g, ""))?.fa;

          return (
            <Grid
              key={idx}
              item
              container
              sx={{
                width: "550px",
                direction: "rtl",
                marginBottom: "16px",
              }}
            >
              <Grid item className={classes.myGrid2}>
                {nameStr.trim()}
              </Grid>

              <Grid item className={classes.myGrid}>
                {tempData?.docFileAddress && tempData.consigneeCanDownload && (
                  <Button
                    sx={{
                      color: "#eda64b",
                      fontFamily: "samim",
                    }}
                    disabled={
                      !tempData?.docFileAddress ||
                      !tempData.consigneeCanDownload
                    }
                    onClick={() => handleDownloadFile(tempData?.docFileAddress)}
                  >
                    دانلود فایل
                  </Button>
                )}
              </Grid>
              <Grid item className={classes.myGrid}>
                {tempData?.template && tempData.consigneeCanDownload && (
                  <Button
                    sx={{
                      color: "#eda64b",
                      fontFamily: "samim",
                    }}
                    disabled={
                      !tempData?.docFileAddress ||
                      !tempData.consigneeCanDownload
                    }
                    onClick={() => handleDownloadFile(tempData?.template)}
                  >
                    دانلود فایل
                  </Button>
                )}
              </Grid>
              <Grid item className={classes.myGrid}>
                {tempData.consigneeCanUpload && (
                  <div>
                    <label htmlFor="contained-button-file">
                      <Button
                        disabled={!tempData.consigneeCanUpload}
                        sx={{
                          color: "#eda64b",
                          position: "relative",
                          fontFamily: "samim",
                          border: "1px solid #eda64b",
                          backgroundColor: "#fff",
                          ":disabled": {
                            border: "1px solid #eda6",
                          },
                          ":hover": {
                            border: "1px solid #eda6",
                            backgroundColor: "#fff",
                          },
                        }}
                      >
                        <input
                          disabled={!tempData.consigneeCanUpload}
                          id="contained-button-file"
                          multiple
                          type="file"
                          style={{
                            opacity: "0",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                          }}
                          onChange={(e: any) => {
                            console.log("tableData[item]::", tableData[item]);
                            handleUpload(
                              e.target.files[0],
                              tableData[item]?.rowId
                            );
                          }}
                        />
                        آپلود
                      </Button>
                    </label>
                  </div>
                )}
              </Grid>
              <Grid item className={classes.myGrid}>
                {tempData.consigneeCanDeleted && tempData?.docFileAddress && (
                  <IconButton
                    disabled={
                      !tempData.consigneeCanDeleted || !tempData?.docFileAddress
                    }
                    color="error"
                    onClick={() => handleDeleteFile(tempData.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
