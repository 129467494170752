import { Button, Row, Col, Spin, Form, Space, Select } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

import { setVoyageToOrder } from '../../../../redux/actions'
import { IState } from '../../../../redux/types'

interface IProps {
  orderId: number | undefined
  closeModal: () => void
}

const { Option } = Select

function SetVesselModal({ orderId, closeModal }: IProps) {
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const voyages = useSelector((state: IState) => state.voyages)
  const setVoyageToOrderState = useSelector((state: IState) => state.setVoyageToOrder)

  return (
    <div>
      <Spin spinning={setVoyageToOrderState.loading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={(info) => {
            dispatch(setVoyageToOrder({ ...info, carryOrderId: orderId }, () => {
              form.resetFields()
              closeModal()
            }))
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="تخصیص سفر"
                name="voyageId"
              >
                <Select style={{ width: '100%', marginTop: 20 }}>
                  {voyages.data.map(item => (
                    <Option value={item.id}>{item.voyageName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className='alignEnd' >
              <Space size={16}>
                <Button htmlType="submit" size="large" type="primary">ذخیره</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  )
}

export default SetVesselModal
