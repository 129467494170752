import { createStore, applyMiddleware, compose } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"

import rootReducer from "./reducers"

const persistConfig = {
  key: "root",
  keyPrefix: "",
  storage,
}

const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer)

const middleWares = [thunk]

let composer
if (process.env.NODE_ENV === "development") {
  /* eslint-disable global-require */
  const { composeWithDevTools } = require("redux-devtools-extension")
  const immutableStateInvariant = require("redux-immutable-state-invariant").default()
  /* eslint-enable global-require */

  composer = composeWithDevTools
  middleWares.push(immutableStateInvariant)
} else {
  composer = compose
}

const store = createStore(
  persistedReducer,
  composer(applyMiddleware(...middleWares))
)

const persistor = persistStore(store)

export { store, persistor }
