import React from "react";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import api from "../../utils/api";
import { toast } from "react-toastify";

const { Dragger } = Upload;

export default function FileUpload() {
  const props = {
    name: "file",
    maxCount: 1,
    multiple: false,
    accept: "image/*",
  };
  return (
    <Dragger {...props} {...actionsProps}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">آپلود لوگو</p>
      <p className="ant-upload-hint">
        با کلیک کردن بر روی باکس یا کشیدن تصویر روی آن لوگو خود را آپلود کنید.{" "}
      </p>
    </Dragger>
  );
}

const actionsProps = {
  customRequest: async function uploadAvatar({ file }: any) {
    var bodyFormData = new FormData();
    bodyFormData.append("avatar", file);
    return await api({
      isFile: true,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `v1.0.0/supplier/uploadAvatar`,
      baseUrl: "https://appsrv1.beautyles.com:8052",
      auth: true,
      data: bodyFormData,
      success: (res) => {
        toast.success("لوگو شما آپلود شد.");
      },
      error: (e) => {
        toast.error("لوگو شما آپلود نشد.");
      },
    });
  },
};
