import styles from './NotFound.module.scss'

function NotFound() {
  return (
    <div className={styles.container}>
    </div>
  )
}

export default NotFound
