import { useEffect } from "react";
import { Spin, Form, Input, Button, Row, Col, Space, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { acceptOrder, getSupplierOrderList } from "../../../../redux/actions";
import { IState } from "../../../../redux/types";

interface IProps {
  orderId: number | undefined;
  closeModal: () => void;
}

const { Option } = Select;
const { TextArea } = Input;

function InvoiceModal({ orderId, closeModal }: IProps) {
  const acceptOrderState = useSelector((state: IState) => state.acceptOrder);
  const inquiryRelations = useSelector(
    (state: IState) => state.inquiryRelations
  );
  const supplierOrderList = useSelector(
    (state: IState) => state.supplierOrderList
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [orderId, closeModal]);

  return (
    <div>
      <Spin spinning={acceptOrderState.loading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={(info) => {
            dispatch(
              acceptOrder(
                {
                  ...info,
                  firstLoadingDate: info.firstLoadingDate
                    ? moment(info.firstLoadingDate).format("x")
                    : "",
                  lastLoadingDate: info.lastLoadingDate
                    ? moment(info.lastLoadingDate).format("x")
                    : "",
                  etd: info.etd ? moment(info.etd).format("x") : "",
                  eta: info.eta ? moment(info.eta).format("x") : "",
                  commodityTypeId: 1,
                  carryOrderId: orderId,
                  price: info.price ? info.price.toEnDigit() : "",
                },
                () => {
                  form.resetFields();
                  closeModal();
                  dispatch(getSupplierOrderList(supplierOrderList.params));
                }
              )
            );
          }}
        >
          <div>
            <Row gutter={[30, 10]}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item label="اولین تاریخ بارگیری" name="firstLoadingDate">
                  <Input allowClear type="datetime-local" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item label="آخرین تاریخ بارگیری" name="lastLoadingDate">
                  <Input allowClear type="datetime-local" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item label="تاریخ تقریبی ترک مبدا" name="etd">
                  <Input allowClear type="datetime-local" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item label="تاریخ تقریبی رسیدن به مقصد" name="eta">
                  <Input allowClear type="datetime-local" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="inqCurrencyId"
                  label="نوع ارز"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Select allowClear showSearch optionFilterProp="children">
                    {inquiryRelations.data.currency.map((item: any) => (
                      <Option value={item.id}>{item.generalName}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="priceTypeId"
                  label="قیمت بر اساس"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Select allowClear showSearch optionFilterProp="children">
                    {inquiryRelations.data.priceType.map((item: any) => (
                      <Option value={item.id}>{item.generalName}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label="قیمت"
                  name="price"
                  rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="توضیحات" name="comment">
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24} className="alignEnd">
              <Space size={16}>
                <Button htmlType="submit" size="large" type="primary">
                  ذخیره
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}

export default InvoiceModal;
