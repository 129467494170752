import { Descriptions } from 'antd'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { IState, IVessel } from '../../../../redux/types'

interface IProps {
  data: IVessel | undefined
}

function ShowModal({ data }: IProps) {
  const vesselRelations = useSelector((state: IState) => state.vesselRelations)

  return (
    <div>
      <Descriptions bordered>
        <Descriptions.Item span={2} label="نام شناور">{data?.name}</Descriptions.Item>
        <Descriptions.Item label="شماره ثبت">{data?.registerNo}</Descriptions.Item>
        <Descriptions.Item span={2} label="نوع شناور">{
          vesselRelations.data.vesselTypes.find(item => item.id === data?.vesselTypeId)?.generalName  
        }</Descriptions.Item>
        <Descriptions.Item label="تحت کلاس">{data?.class}</Descriptions.Item>
        <Descriptions.Item span={2} label="بندر ثبت">{
          vesselRelations.data.registerPorts.find(item => item.id === data?.registerPortId)?.locationName
        }</Descriptions.Item>
        <Descriptions.Item label="سال ساخت">{data?.buildYear}</Descriptions.Item>
        <Descriptions.Item span={2} label="تاریخ ثبت">{data?.registerDate ? moment(parseInt(data?.registerDate)).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
        <Descriptions.Item label="IMO number">{data?.imoCode}</Descriptions.Item>
        <Descriptions.Item span={2} label={<span>علامت خطاب<br />Call Sign</span>}>{data?.callSign}</Descriptions.Item>
        <Descriptions.Item label="پرچم">{
          vesselRelations.data.flags.find(item => item.id === data?.flagId)?.name
        }</Descriptions.Item>
        <Descriptions.Item label="ظرفیت (DWT)">{data?.tonnage}</Descriptions.Item>
        <Descriptions.Item label="ظرفیت (TEU)">{data?.teu}</Descriptions.Item>
        <Descriptions.Item label="درافت">{data?.draft}</Descriptions.Item>
        <Descriptions.Item span={3} label="نام فرمانده">{data?.captainName}</Descriptions.Item>
        <Descriptions.Item span={3} label="شماره تماس">{data?.contactNo}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default ShowModal
