import { toast} from 'react-toastify'

import { IState, IUser, ISupplier, IConsignee, IConsigneeOrderListParams, IVessel, IVoyage } from './types'
import api from '../utils/api'

interface ISigninPassword {
  phone: string,
  password: string,
  userType: "1" | "2",
}

export function signinPassword(data: ISigninPassword, callback: (data: IUser) => void) {
  return async function signinPasswordThunk(dispatch: any, getState: any) {
    const state: IState = getState()
    dispatch({
      type: 'signin',
      payload: { 
        loading: true,
        number: state.signin.number
      }
    })

    return await api({
      method: "POST",
      url: `v1.0.0/account/loginWithPassword`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      data: { ...data, phone: state.signin.number},
      success: (res) => {
        dispatch({
          type: 'signin',
          payload: { 
            loading: false,
            number: state.signin.number
          }
        })
        signinAfter(res.data.user, dispatch, res.data)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'signin',
          payload: { 
            loading: false,
            number: state.signin.number
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

// export function uploadImage(data: any, callback: (data: IUser) => void) {
//   return async function uploadImageThunk(dispatch: any, getState: any) {
//     var bodyFormData = new FormData();
//     bodyFormData.append("avatar", data);
//     return await api({
//       isFile: true,
//       method: "POST",
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//       url: `v1.0.0/supplier/uploadAvatar`,
//       baseUrl: "https://appsrv1.beautyles.com:8052",
//       auth: true,
//       data: bodyFormData,
//       success: (res) => {
//         toast.success("لوگو شما آپلود شد.");
//       },
//       error: (e) => {
//         toast.error("لوگو شما آپلود نشد.");
//       },
//     });
//   }
// }

export function signupCode(number: string, callback: () => void) {
  return async function signupCodeThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'signup',
      payload: { 
        loading: true,
        number: number
      }
    })

    return await api({
      method: "POST",
      url: `v1.0.0/account/isRegistered`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      data: {
        phone: number
      },
      success: (res) => {
        dispatch({
          type: 'signup',
          payload: { 
            loading: false,
            number: number
          }
        })
        toast.success('رمز یکبار مصرف ارسال شد.')
        callback()
      },
      error: (e) => {
        dispatch({
          type: 'signup',
          payload: { 
            loading: false,
            number: number
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}



export function signinEmailCode(callback: () => void) {
  return async function signinEmailCodeThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'signin',
      payload: { 
        loading: true,
        number: state.signin.number
      }
    })

    return await api({
      method: "POST",
      url: `v1.0.0/account/login/sendCodeEmail`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      data: {
        phone: state.signin.number
      },
      success: (res) => {
        dispatch({
          type: 'signin',
          payload: { 
            loading: false,
            number: state.signin.number
          }
        })
        toast.success('رمز یکبار مصرف ارسال شد.')
        callback()
      },
      error: (e) => {
        dispatch({
          type: 'signin',
          payload: { 
            loading: false,
            number: state.signin.number
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function signinPhoneCode(callback: () => void) {
  return async function signinPhoneCodeThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'signin',
      payload: { 
        loading: true,
        number: state.signin.number
      }
    })

    return await api({
      method: "POST",
      url: `v1.0.0/account/login/sendCodePhone`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      data: {
        phone: state.signin.number
      },
      success: (res) => {
        dispatch({
          type: 'signin',
          payload: { 
            loading: false,
            number: state.signin.number
          }
        })
        toast.success('رمز یکبار مصرف ارسال شد.')
        callback()
      },
      error: (e) => {
        dispatch({
          type: 'signin',
          payload: { 
            loading: false,
            number: state.signin.number
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function signin(code: string, userType: "1" | "2" , callback: (data: IUser) => void) {
  return async function signinThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'signin',
      payload: { 
        loading: true,
        number: state.signin.number
      }
    })

    return await api({
      method: "POST",
      url: `v1.0.0/account/loginWithCode`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      data: {
        phone: state.signin.number,
        code,
        userType,
      },
      success: (res) => {
        dispatch({
          type: 'signin',
          payload: { 
            loading: false,
            number: state.signin.number
          }
        })
        signinAfter(res.data.user, dispatch, res.data)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'signin',
          payload: { 
            loading: false,
            number: state.signin.number
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function signup(code: string, userType: number, callback: (data: IUser) => void) {
  return async function signupThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'signup',
      payload: { 
        loading: true,
        number: state.signup.number
      }
    })

    return await api({
      method: "POST",
      url: `v1.0.0/account/register`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      data: {
        phone: state.signup.number,
        code,
        userType,
      },
      success: (res) => {
        dispatch({
          type: 'signup',
          payload: { 
            loading: false,
            number: state.signup.number
          }
        })
        signinAfter(res.data.user, dispatch, res.data)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'signup',
          payload: { 
            loading: false,
            number: state.signup.number
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function signinAfter(user: IUser, dispatch: any, data: any) {
  dispatch({
    type: 'user',
    payload: {
      loading: false,
      data: data.user,
    }
  })
  dispatch({
    type: 'token',
    payload: data.token
  })

  if (user.userTypeId === 2) {
    dispatch(getSupplier(() => {
      dispatch(inquiryRelations(() => {}))
      dispatch(voyageRelations(() => {}))
      dispatch(vesselRelations(() => {}))
      dispatch(supplierRelations(() => {}))
    }))
  } else if (user.userTypeId === 1) {
    dispatch(getConsignee(() => {
      dispatch(consigneeRelations(() => {}))
    }))
  }
}

export function signout(callback: () => void) {
  return async function signoutThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'token',
      payload: '',
    })
    dispatch({
      type: 'user',
      payload: {
        loading: false,
        data: {},
      }
    })
    dispatch({
      type: 'signup',
      payload: {
        loading: false,
        number: '',
      },
    })
    dispatch({
      type: 'addOrder',
      payload: {
        loading: false,
      },
    })
    dispatch({
      type: 'acceptOrder',
      payload: {
        loading: false,
      },
    })
    dispatch({
      type: 'rejectOrder',
      payload: {
        loading: false,
      },
    })
    dispatch({
      type: 'user',
      payload: {
        loading: false,
        data: {
          name: '',
          phone: '',
          userId: -1,
          userTypeId: 1,
          username: '',
          email: '',
        }
      },
    })

    dispatch({
      type: 'consigneeRelations',
      payload: {
        loading: false,
        data: {
          currency: [],
          dgCode: [],
          dischargingPort: [],
          loadingPort: [],
          packageType: [],
          commodityType: [],
          numberOfCommodityTypes: 0,
          numberOfCurrency: 0,
          numberOfDGCode: 0,
          numberOfDischargingPort: 0,
          numberOfLoadingPort: 0,
          numberOfPackageType: 0,
        }
      },
    })

    dispatch({
      type: 'supplierRelations',
      payload: {
        loading: false,
        data: {
          currency: [],
          dgCode: [],
          dischargingPort: [],
          loadingPort: [],
          packageType: [],
          commodityType: [],
          numberOfCommodityTypes: 0,
          numberOfCurrency: 0,
          numberOfDGCode: 0,
          numberOfDischargingPort: 0,
          numberOfLoadingPort: 0,
          numberOfPackageType: 0,
        }
      },
    })

    dispatch({
      type: 'consignee',
      payload: {
        loading: false,
        data: {
          name: '',
          idCode: '',
          economicCode: '',
          phone: '',
          fax: '',
          email: '',
          contactName: '',
          address: '',
          postalCode: '',
          userId: '',
        }
      },
    })

    dispatch({
      type: 'supplier',
      payload: {
        loading: false,
        data: {
          idCode: '',
          name: '', 
          economicCode: '', 
          bankAccount: '', 
          phone: '', 
          fax: '', 
          email: '',
          contactName: '',
          address: '',
          postalCode: '',
          webSite: '',
        }
      },
    })

    dispatch({
      type: 'consigneeOrderList',
      payload: {
        loading: false,
        params: {
          searchName: '',
          dateStart: '',
          dateEnd: '',
          dischargingPortId: 0,
          loadingPortId: 0,
          limit: 10,
          page: 0,
          count: 0,
        },
        data: {
          numberOfOrderListComplete: 0,
          numberOfOrderListConfirm: 0,
          numberOfOrderListHaveInquiries: 0,
          numberOfOrderListClosed: 0,
          orderListComplete: [],
          orderListConfirm: [],
          orderListHaveInquiries: [],
          orderListClosed: [],
        }
      },
    })

    dispatch({
      type: 'supplierOrderList',
      payload: {
        loading: false,
        params: {
          searchName: '',
          dateStart: '',
          dateEnd: '',
          dischargingPortId: 0,
          loadingPortId: 0,
          limit: 10,
          page: 1,
          count: 0,
        },
        data: {
          numberOfOrderListUnseen: 0,
          numberOfOrderListAccept: 0,
          numberOfOrderFailed: 0,
          numberOfOrderConfirm: 0,
          orderListUnseen: [],
          orderListAccept: [],
          orderListFailed: [],
          orderListConfirm: [],
        }
      },
    })

    dispatch({
      type: 'vesselRelations',
      payload: {
        loading: false,
        data: {
          flags: [],
          numberOfFlags: 0,
          numberOfRegisterPorts: 0,
          numberOfVesselTypes: 0,
          registerPorts: [],
          vesselTypes: []
        }
      },
    })

    dispatch({
      type: 'voyageRelations',
      payload: {
        loading: false,
        data: {
          dischagingPort: [],
          dischargingPort: [],
          numberOfDischagingPort: 0,
          numberOfDischargingPort: 0
        }
      },
    })

    dispatch({
      type: 'inquiryRelations',
      payload: {
        loading: false,
        data: {
          commodityTypes: [],
          priceType: [],
          numberOfCommodityType: 0,
          numberOfPriceType: 0
        }
      },
    })

    dispatch({
      type: 'vessels',
      payload: {
        loading: false,
        params: {
          limit: 10,
          page: 0,
          count: 0,
        },
        data: []
      },
    })

    dispatch({
      type: 'addVessel',
      payload: {
        loading: false,
      },
    })

    dispatch({
      type: 'deleteVessel',
      payload: {
        loading: false,
      },
    })

    dispatch({
      type: 'voyages',
      payload: {
        loading: false,
        params: {
          limit: 10,
          page: 0,
          count: 0,
        },
        data: []
      },
    })

    dispatch({
      type: 'addVoyage',
      payload: {
        loading: false,
      },
    })

    dispatch({
      type: 'deleteVoyage',
      payload: {
        loading: false,
      },
    })

    dispatch({
      type: 'orderInquiries',
      payload: {
        loading: false,
        data: {
          currencyId: -1,
          customValue: -1,
          dGCodeId: -1,
          etl: '',
          goodDescription: '',
          grossWeight: '',
          id: -1,
          loadingPortId: -1,
          netWeight: '',
          orderDate: '',
          orderLongNo: '',
          orderShortNo: '',
          packageTypeId: -1,
          qty: '',
          consigneeFirstNameFa: '',
          consigneeAddress: '',
          consigneePhoneNumber: '',
          consigneeLastNameFa: '',
          consigneeFirstNameEn: '',
          consigneeLastNameEn: '',
          shipperFirstNameFa: '',
          shipperAddress: '',
          shipperPhoneNumber: '',
          shipperLastNameFa: '',
          shipperFirstNameEn: '',
          shipperLastNameEn: '',
          notifyFirstNameFa: '',
          notifyAddress: '',
          notifyPhoneNumber: '',
          notifyLastNameFa: '',
          notifyFirstNameEn: '',
          notifyLastNameEn: '',
        }
      },
    })

    dispatch({
      type: 'setVoyageToOrder',
      payload: {
        loading: false,
      }
    })

    callback()
  }
}

export function editUser(data: any, callback: (data: any) => void) {
  return async function editUserThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'user',
      payload: { 
        loading: true,
        data: state.user.data
      }
    })

    return await api({
      method: "PUT",
      url: `v1.0.0/user/update`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      data,
      success: (res) => {
        dispatch({
          type: 'user',
          payload: { 
            loading: false,
            data: res.data.user
          }
        })
        toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'user',
          payload: { 
            loading: false,
            data: state.user.data
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}


export function addConsignee(method: "PUT" | "POST", data: IUser, callback: (data: IUser) => void) {
  return async function addConsigneeThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'addConsignee',
      payload: { 
        loading: true,
        data: state.user.data
      }
    })

    return await api({
      method,
      url: `v1.0.0/consignee/${method === "PUT" ? 'edit' : 'add'}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      data,
      success: (res) => {
        dispatch({
          type: 'addConsignee',
          payload: { 
            loading: false,
            data: state.user.data
          }
        })
        toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'addConsignee',
          payload: { 
            loading: false,
            data: state.user.data
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function addSupplier(method: "PUT" | "POST", data: IUser, callback: (data: IUser) => void) {
  return async function addSupplierThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'addSupplier',
      payload: { 
        loading: true,
        data: state.user.data
      }
    })

    return await api({
      method: method,
      url: `v1.0.0/supplier/${method === "PUT" ? 'edit' : 'add'}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      data,
      success: (res) => {
        dispatch({
          type: 'addSupplier',
          payload: { 
            loading: false,
            data: state.user.data
          }
        })
        toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'addSupplier',
          payload: { 
            loading: false,
            data: state.user.data
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function addOrder(method: "PUT" | "POST", data: IUser, callback: (data: IUser) => void, id?: string ,) {
  return async function addOrderThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'addOrder',
      payload: { 
        loading: true,
      }
    })

    return await api({
      method: method,
      url: `v1.0.0/carryOrder/${method === "PUT" ? 'edit/' + id  : 'add/'}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      isFile : true,
      data,
      success: (res) => {
        dispatch({
          type: 'addOrder',
          payload: { 
            loading: false,
          }
        })
        toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'addOrder',
          payload: { 
            loading: false,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function consigneeRelations(callback?: () => void) {
  return async function consigneeRelationsThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'consigneeRelations',
      payload: { 
        loading: true,
        data: state.consigneeRelations.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/carryOrder/getCarryOrderRelation`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'consigneeRelations',
          payload: { 
            loading: false,
            data: res.data,
          }
        })
        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'consigneeRelations',
          payload: { 
            loading: false,
            data: state.consigneeRelations.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}


export function getOneCarryOrder( carryOrderId:any,callback?: () => void) {
  return async function getOneCarryOrderThunk(dispatch: any, getState: any) {
    // oneCarryOrder: {
    //   loading: false,
    //   data: null,
    // },
    const state: IState = getState()
    dispatch({
      type: 'oneCarryOrder',
      payload: { 
        loading: true,
        data: state.oneCarryOrder.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/carryOrder/getOne/${carryOrderId}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'oneCarryOrder',
          payload: { 
            loading: false,
            data: res.data.carryOrder,
          }
        })
        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'oneCarryOrder',
          payload: { 
            loading: false,
            data: state.consigneeRelations.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function getOneCarryOrderSupp( carryOrderId:any,callback?: () => void) {
  return async function getOneCarryOrderSuppThunk(dispatch: any, getState: any) {
    // oneCarryOrder: {
    //   loading: false,
    //   data: null,
    // },
    const state: IState = getState()
    dispatch({
      type: 'oneCarryOrderSupp',
      payload: { 
        loading: true,
        data: state.oneCarryOrder.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/supplierDashboard/getOneCarryOrder/${carryOrderId}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'oneCarryOrderSupp',
          payload: { 
            loading: false,
            data: res.data.carryOrder,
          }
        })
        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'oneCarryOrderSupp',
          payload: { 
            loading: false,
            data: state.consigneeRelations.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export async function getImage(path : string) {
     try {
      const res = api({
        method: "GET",
        url: `v1.0.0/image/${path}`,
        baseUrl: 'https://appsrv1.beautyles.com:8052',
        auth: true,
        success: (res) => {
          return (res.data)
        },
        error: (e) => {
          toast.error(e.response?.data?.message?.fa)
          return(e)
        },
      })

      console.log("RESSSSSSSSSSSSS:" , res);
      
     } catch (e) {
       console.log("eeee:" , e);
       
     }

  }

export function supplierRelations(callback?: () => void) {
  return async function supplierRelationsThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'supplierRelations',
      payload: { 
        loading: true,
        data: state.supplierRelations.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/supplierDashboard/getCarryOrderRelation`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'supplierRelations',
          payload: { 
            loading: false,
            data: res.data,
          }
        })
        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'supplierRelations',
          payload: { 
            loading: false,
            data: state.supplierRelations.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function vesselRelations(callback: (data: IUser) => void) {
  return async function vesselRelationsThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'vesselRelations',
      payload: { 
        loading: true,
        data: state.vesselRelations.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/vessel/getVesselRelation`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'vesselRelations',
          payload: { 
            loading: false,
            data: res.data,
          }
        })
        // toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'vesselRelations',
          payload: { 
            loading: false,
            data: state.vesselRelations.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function voyageRelations(callback: (data: IUser) => void) {
  return async function voyageRelationsThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'voyageRelations',
      payload: { 
        loading: true,
        data: state.voyageRelations.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/voyage/getVoyageRelation`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'voyageRelations',
          payload: { 
            loading: false,
            data: res.data,
          }
        })
        // toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'voyageRelations',
          payload: { 
            loading: false,
            data: state.voyageRelations.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function inquiryRelations(callback: (data: IUser) => void) {
  return async function inquiryRelationsThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'inquiryRelations',
      payload: { 
        loading: true,
        data: state.inquiryRelations.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/supplierDashboard/getInquiriesRelation`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'inquiryRelations',
          payload: { 
            loading: false,
            data: res.data,
          }
        })
        // toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'inquiryRelations',
          payload: { 
            loading: false,
            data: state.inquiryRelations.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function getConsignee(callback: (data: IConsignee) => void) {
  return async function getConsigneeThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'consignee',
      payload: { 
        loading: true,
        data: state.consignee.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/consignee/getOne`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'consignee',
          payload: { 
            loading: false,
            data: res.data.consignee,
          }
        })
        callback(res.data.consigneeFind)
      },
      error: (e) => {
        console.log(e)
        dispatch({
          type: 'consignee',
          payload: { 
            loading: false,
            data: state.consignee.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}
// sdddddddddddddddddddddddddddddddd
// function getBase64(file:any) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
//   });
// }
function b64toBlob(b64Data:any, contentType:any) {
  contentType = contentType || '';
  var sliceSize = 512;
  b64Data = b64Data.replace(/^[^,]+,/, '');
  b64Data = b64Data.replace(/\s/g, '');
  var byteCharacters = window.atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export async function docDownload(path:string , carryOrderId:number) {    
    return await api({
      isFile: true,
      method: "GET",
      isPdf: true,
      url: `v1.0.0/document/getDocFile/${path}?carryOrderId=${carryOrderId}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      
      success: (res) => {
        var blobUrl = URL.createObjectURL(res.data);  
        // var blob = new Blob([res.data], { type: 'application/pdf' });
        // let buffer = new Buffer(res.data, 'binary');
        // let base64data = buffer.toString('base64');
        // let blob = b64toBlob(base64data , "application/pdf");
        // const url = window.URL.createObjectURL(blob);

        // window.saveAs(blob, "application/pdf")
        //  window.open("data:application/pdf;base64, " + data, '', "height=600,width=800");
        // window.location.href = 'data:application/octet-stream;base64,' + base64data;
        
        // const url = window.URL.createObjectURL(new Blob([res.data]));
  
        // const url = window.URL.createObjectURL(new Blob([res.data],{type:"application/pdf"}));
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "docFile.pdf"; 
        a.click();
      },
      error: (e) => {
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }

// ddddddddddddddddddddddddddddddddddddddddd


export function deletetDoc(id:any) {
  return async function docTypesThunk(dispatch: any, getState: any) {

    return await api({
      method: "DELETE",
      url: `v1.0.0/document/deleteDoc/${id}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        toast.success(res.data?.message?.fa)
      },
      error: (e) => {
        toast.error("مشکلی پیش آمده است.")
      },
    })
  }
}



export function docTypes() {
  return async function docTypesThunk(dispatch: any, getState: any) {
        // allDocTypes: {
    //   loading: false,
    //   data: null,
    // },
    // carryOrderDocTypes: {
    //   loading: false,
    //   data: null,
    // },

    // documents: {
    //   loading: false,
    //   allDocTypes: null,
    //   docTypes: null,
    // },
    dispatch({
      type: 'allDocTypes',
      payload: { 
        loading: true,
        data: [],
      }
    })
    return await api({
      method: "GET",
      url: `v1.0.0/document/getAllDocTypes`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'allDocTypes',
          payload: { 
            loading: false,
            data: res.data.docTypes,
          }
        })
      },
      error: (e) => {
        dispatch({
          type: 'allDocTypes',
          payload: { 
            loading: false,
            data: [],
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function getMyDocTypes(docsModalCarryOrderID:any) {
  return async function getMyDocTypesThunk(dispatch: any, getState: any) {
    // allDocTypes: {
    //   loading: false,
    //   data: null,
    // },
    // carryOrderDocTypes: {
    //   loading: false,
    //   data: null,
    // },
    dispatch({
      type: 'carryOrderDocTypes',
      payload: { 
        loading: true,
        data: []
      }
    })
    return await api({
      method: "GET",
      url: `v1.0.0/document/getAllDocs/${docsModalCarryOrderID}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'carryOrderDocTypes',
          payload: { 
            loading: false,
            data: res.data?.docs,
          }
        })
      },
      error: (e) => {
        dispatch({
          type: 'carryOrderDocTypes',
          payload: { 
            loading: false,
            data: [],
          }
        })
      },
    })
  }
}

export function getSupplier(callback: (data: ISupplier) => void) {
  return async function getSupplierThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'supplier',
      payload: { 
        loading: true,
        data: state.supplier.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/supplier/getOne`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'supplier',
          payload: { 
            loading: false,
            data: res.data.supplier,
          }
        })
        callback(res.data.supplierFind)
      },
      error: (e) => {
        dispatch({
          type: 'supplier',
          payload: { 
            loading: false,
            data: state.supplier.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function getConsigneeOrderList(params: IConsigneeOrderListParams, callback?: () => void) {
  return async function getConsigneeOrderThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'consigneeOrderList',
      payload: { 
        loading: true,
        params: state.consigneeOrderList.params,
        data: state.consigneeOrderList.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/consigneeDashboard/getOrderListHaveInquiries`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      params,
      auth: true,
      success: (res) => {
        dispatch({
          type: 'consigneeOrderList',
          payload: { 
            loading: false,
            params: {...params, ...{ count: res.data.numberOfVessels }},
            data: res.data,
          }
        })
        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'consigneeOrderList',
          payload: { 
            loading: false,
            params: state.consigneeOrderList.params,
            data: state.consigneeOrderList.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function getSupplierOrderList(params: IConsigneeOrderListParams, callback?: () => void) {
  return async function getSupplierOrderThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'supplierOrderList',
      payload: { 
        loading: true,
        params: state.supplierOrderList.params,
        data: state.supplierOrderList.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/supplierDashboard/getOrdersList`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      params,
      auth: true,
      success: (res) => {
        dispatch({
          type: 'supplierOrderList',
          payload: { 
            loading: false,
            params: {...params, ...{ count: res.data.numberOfVessels }},
            data: res.data,
          }
        })
        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'supplierOrderList',
          payload: { 
            loading: false,
            params: state.supplierOrderList.params,
            data: state.supplierOrderList.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function getVessels(params?: IConsigneeOrderListParams, callback?: () => void) {
  return async function getVesselsThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'vessels',
      payload: { 
        loading: true,
        params: state.vessels.params,
        data: state.vessels.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/vessel/getAll`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      params,
      auth: true,
      success: (res) => {
        dispatch({
          type: 'vessels',
          payload: { 
            loading: false,
            params: {...params, ...{ count: res.data.numberOfVessels }},
            data: res.data.vessels,
          }
        })
        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'vessels',
          payload: { 
            loading: false,
            params: state.vessels.params,
            data: state.vessels.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function addVessel(method: "PUT" | "POST", data: IVessel, callback: (data: IUser) => void, id?: number) {
  return async function addVesselThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'addVessel',
      payload: { 
        loading: true,
      }
    })
   
    
    return await api({
      method: method,
      url: `v1.0.0/vessel/${method === "PUT" ? 'edit/' + id  : 'add'}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      customHeader: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Content-Length": "<calculated when request is sent>",
        "Accept": "*/*",
        "Accept-Encoding": "gzip, deflate, br",
        "Connection": "keep-alive",
      },
      data,
      success: (res) => {
        dispatch({
          type: 'addVessel',
          payload: { 
            loading: false,
          }
        })
        toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'addVessel',
          payload: { 
            loading: false,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function deleteVessel(vesselId: number, callback: (data: IUser) => void) {
  return async function deleteVesselThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'deleteVessel',
      payload: { 
        loading: true,
      }
    })

    return await api({
      method: "DELETE",
      url: `v1.0.0/vessel/delete/${vesselId}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'deleteVessel',
          payload: { 
            loading: false,
          }
        })
        toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'deleteVessel',
          payload: { 
            loading: false,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function getVoyages(params: any, callback?: () => void) {
  return async function getVoyagesThunk(dispatch: any, getState: any) {
    const state: IState = getState()
    
    dispatch({
      type: 'voyages',
      payload: { 
        loading: true,
        params: state.voyages.params,
        data: state.voyages.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/voyage/getAll`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      params,
      auth: true,
      success: (res) => {
        dispatch({
          type: 'voyages',
          payload: { 
            loading: false,
            params: {...params, ...{ count: res.data.numberOfVoyages }},
            data: res.data.voyages,
          }
        })
        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'voyages',
          payload: { 
            loading: false,
            params: state.voyages.params,
            data: state.voyages.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function addVoyage(method: "PUT" | "POST", data: IVoyage, callback: (data: IUser) => void, id?: number) {
  return async function addVoyageThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'addVoyage',
      payload: { 
        loading: true,
      }
    })

    return await api({
      method: method,
      url: `v1.0.0/voyage/${method === "PUT" ? 'edit/' + id  : 'add'}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      data,
      success: (res) => {
        dispatch({
          type: 'addVoyage',
          payload: { 
            loading: false,
          }
        })
        toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'addVoyage',
          payload: { 
            loading: false,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function deleteVoyage(voyageId: number, callback: (data: IUser) => void) {
  return async function deleteVoyageThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'deleteVoyage',
      payload: { 
        loading: true,
      }
    })

    return await api({
      method: "DELETE",
      url: `v1.0.0/voyage/delete/${voyageId}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'deleteVoyage',
          payload: { 
            loading: false,
          }
        })
        toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'deleteVoyage',
          payload: { 
            loading: false,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function acceptOrder(data: IVoyage, callback: (data: IUser) => void) {
  return async function acceptOrderThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'acceptOrder',
      payload: { 
        loading: true,
      }
    })

    return await api({
      method: "PUT",
      url: `v1.0.0/supplierDashboard/addInquieriesToOrder`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      data,
      success: (res) => {
        dispatch({
          type: 'acceptOrder',
          payload: { 
            loading: false,
          }
        })
        toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'acceptOrder',
          payload: { 
            loading: false,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function rejectOrder(data: IVoyage, callback: (data: IUser) => void) {
  return async function rejectOrderThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'rejectOrder',
      payload: { 
        loading: true,
      }
    })

    return await api({
      method: "PUT",
      url: `v1.0.0/supplierDashboard/rejectOrderList`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      data,
      success: (res) => {
        dispatch({
          type: 'rejectOrder',
          payload: { 
            loading: false,
          }
        })
        toast.success(res.data.message.fa)
        callback(res.data.user)
      },
      error: (e) => {
        dispatch({
          type: 'rejectOrder',
          payload: { 
            loading: false,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function getOrderInquiries(id: number, callback?: () => void) {
  return async function getOrderInquiriesThunk(dispatch: any, getState: any) {
    const state: IState = getState()

    dispatch({
      type: 'orderInquiries',
      payload: { 
        loading: true,
        data: state.orderInquiries.data,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/consigneeDashboard/getOrderInquiries/${id}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'orderInquiries',
          payload: { 
            loading: false,
            data: res.data?.carryOrderInquires,
          }
        })
        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'orderInquiries',
          payload: { 
            loading: false,
            data: state.orderInquiries.data,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function acceptInquiry(id: number , carryOrderId : number , callback?: () => void) {
  return async function acceptInquiryThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'acceptInquiry',
      payload: { 
        loading: true,
      }
    })

    return await api({
      method: "POST",
      url: `v1.0.0/consigneeDashboard/selectInquiriesToOrder/${carryOrderId}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      data: {
        inquireId: id
      },
      success: (res) => {
        dispatch({
          type: 'acceptInquiry',
          payload: { 
            loading: false,
          }
        })

        toast.success(res.data?.message?.fa)

        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'acceptInquiry',
          payload: { 
            loading: false,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function setVoyageToOrder(data: ISigninPassword, callback?: () => void) {
  return async function setVoyageToOrderThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'setVoyageToOrder',
      payload: { 
        loading: true,
      }
    })

    return await api({
      method: "POST",
      url: `v1.0.0/supplierDashboard/addVoyageToOrder`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      data,
      success: (res) => {
        dispatch({
          type: 'setVoyageToOrder',
          payload: { 
            loading: false,
          }
        })

        toast.success(res.data?.message?.fa)

        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'setVoyageToOrder',
          payload: { 
            loading: false,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function getPaymentOrder(id: number, callback?: (data: any) => void) {
  return async function getPaymentOrderThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'getPaymentOrder',
      payload: { 
        loading: true,
      }
    })

    return await api({
      method: "GET",
      url: `v1.0.0/consigneeDashboard/getPaymentCarryOrder/${id}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'getPaymentOrder',
          payload: { 
            loading: false,
          }
        })
        if (callback) {
          callback(res?.data)
        }
      },
      error: (e) => {
        dispatch({
          type: 'getPaymentOrder',
          payload: { 
            loading: false,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}

export function addPaymentOrder(id: number, callback?: () => void) {
  return async function addPaymentOrderThunk(dispatch: any, getState: any) {
    dispatch({
      type: 'addPaymentOrder',
      payload: { 
        loading: true,
      }
    })

    return await api({
      method: "POST",
      url: `v1.0.0/consigneeDashboard/addPaymentCarryOrder/${id}`,
      baseUrl: 'https://appsrv1.beautyles.com:8052',
      auth: true,
      success: (res) => {
        dispatch({
          type: 'addPaymentOrder',
          payload: { 
            loading: false,
          }
        })

        toast.success(res.data?.message?.fa)

        if (callback) {
          callback()
        }
      },
      error: (e) => {
        dispatch({
          type: 'addPaymentOrder',
          payload: { 
            loading: false,
          }
        })
        toast.error(e.response?.data?.message?.fa)
      },
    })
  }
}