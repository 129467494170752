import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Box from '../../components/AuthBox/AuthBox'
import { signin, signup } from '../../redux/actions'
import { IState } from '../../redux/types'

import styles from './Signin.module.scss'

function Code() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const signinngin = useSelector((state: IState) => state.signin)
  const signinngup = useSelector((state: IState) => state.signup)

  return (
    <div className={styles.container}>
      <Box
        description={'رمز ارسال شده را وارد کنید.'}
        actions={[
          {
            label: 'ارسال',
            onClick: (values) => {
              if (location.state.type === 'signin') {
                dispatch(signin(values.code.toEnDigit(), location.state?.userType || 1, (data) => {
                  history.push(`/panel/home`)
                }))
              } else if (location.state.type === 'signup') {
                dispatch(signup(values.code.toEnDigit(), location.state?.userType || 1, (data) => {
                  history.push(`/panel/home`)
                }))
              }
            },
            main: true,
            type: 'primary',
          }
        ]}
        form={[
          { 
            name: 'code',
            label: 'رمز یکبار مصرف',
            type: 'text',
            rules: [
              { required: true, message: 'رمز را وارد کنید!' },
            ],
          }
        ]}
        loading={signinngin.loading || signinngup.loading}
      >
      </Box>
    </div>
  )
}

export default Code
