import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import fa_IR from "antd/lib/locale/fa_IR";
import { IState } from "./redux/types";
import LayoutLanding from "./components/LandingLayout/Layout";
import LayoutPanel from "./components/PanelLayout/Layout";
import Landing from "./views/Landing/Landing";
import NotFound from "./views/NotFound/NotFound";
import Signup from "./views/Signin/Signup";
import Signin from "./views/Signin/Signin";
import Code from "./views/Signin/Code";
import SigninPassword from "./views/Signin/SigninPassword";
import ConsigneeHome from "./views/Consignee/Home/Home";
import Inquiries from "./views/Consignee/Inquiry";
import Inquiry from "./views/Consignee/Inquiry/Inquiry";
import Profile from "./views/Consignee/Profile/Profile";
import AddOrder from "./views/Consignee/AddOrder/AddOrder";
import SupplierHome from "./views/Supplier/Home/Home";
import Vessel from "./views/Supplier/Vessel/Vessel";
import Voyage from "./views/Supplier/Voyage/Voyage";
import ProfileS from "./views/Supplier/Profile/Profile";

import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import "./theme/main.scss";
import "./theme/main.less";

// function onRouteChanged(token: string) {
//   if (token.length > 1) {
//     history.push("/")
//     history.go()
//   }
// }

function checkToken(token: string) {
  console.log("token:::", token);

  if (token.length > 1) {
    return true;
  }
  return false;
}

function App() {
  const token = useSelector((state: IState) => state.token);
  const Mstate = useSelector((state: IState) => state);
  const user = useSelector((state: IState) => state.user);

  useEffect(() => {
    // @ts-ignore
    String.prototype.toEnDigit = function () {
      return this.replace(/[\u06F0-\u06F9]+/g, function (digit) {
        var ret = "";
        for (var i = 0, len = digit.length; i < len; i++) {
          ret += String.fromCharCode(digit.charCodeAt(i) - 1728);
        }

        return ret;
      });
    };
  }, []);

  return (
    <ConfigProvider locale={fa_IR} direction="rtl">
      <Router>
        <ToastContainer
          closeButton={false}
          hideProgressBar={true}
          position="top-left"
          transition={Slide}
          autoClose={3000}
          rtl={true}
        />
        <Switch>
          <Route
            path="/panel"
            render={() => {
              const tokenLength = token.length;
              if (tokenLength > 1) {
                return (
                  <LayoutPanel userType={user.data.userTypeId}>
                    <Switch>
                      {user.data.userTypeId === 2 ? (
                        <>
                          <Route
                            exact
                            path="/panel/home"
                            render={() => {
                              return <SupplierHome />;
                            }}
                          />
                          <Route
                            exact
                            path="/panel/vessel"
                            render={() => {
                              return <Vessel />;
                            }}
                          />
                          <Route
                            exact
                            path="/panel/voyage"
                            render={() => {
                              return <Voyage />;
                            }}
                          />
                          <Route
                            exact
                            path="/panel/profile"
                            render={() => {
                              return <ProfileS />;
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Route
                            exact
                            path="/panel/home"
                            render={() => {
                              return <ConsigneeHome />;
                            }}
                          />
                          <Route
                            exact
                            path="/panel/add-order"
                            render={() => {
                              return <AddOrder />;
                            }}
                          />
                          <Route
                            exact
                            path="/panel/home/inquiries"
                            render={() => {
                              return <Inquiries />;
                            }}
                          />
                          <Route
                            exact
                            path="/panel/home/inquiry"
                            render={() => {
                              return <Inquiry />;
                            }}
                          />
                          <Route
                            exact
                            path="/panel/profile"
                            render={() => {
                              return <Profile />;
                            }}
                          />
                        </>
                      )}
                      <Route render={() => <NotFound />} />
                    </Switch>
                  </LayoutPanel>
                );
              }
              return <Route component={NotFound} />;
            }}
          />
          <LayoutLanding>
            <Route
              path="/"
              render={() => {
                if (checkToken(token)) {
                  return <Redirect to="/panel/home" />;
                }
                return (
                  <Switch>
                    <Route exact path="/" component={Landing} />
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path="/signin" component={Signin} />
                    <Route exact path="/code" component={Code} />
                    <Route
                      exact
                      path="/signin/password"
                      component={SigninPassword}
                    />
                    <Route render={() => <NotFound />} />
                  </Switch>
                );
              }}
            />
          </LayoutLanding>
        </Switch>
      </Router>
    </ConfigProvider>
  );
}

export default App;
