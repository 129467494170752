import axios, { AxiosResponse, AxiosError, Method } from "axios"
import qs from 'qs'
import { store } from '../redux/store'
import { signout } from '../redux/actions'

type Params = {
  method: Method,
  data?: any,
  params?: any,
  headers?: any,
  url: string,
  baseUrl: string | undefined,
  success: (res: AxiosResponse) => void,
  error: (e: AxiosError) => void,
  auth?: boolean,
  isFile?: boolean,
  isPdf?: boolean,
  customHeader?: boolean
}

export default async function apiCall({ method, params, url, auth, baseUrl, data, headers = {}, success, error, isFile = false , isPdf= false , customHeader= false }: Params) {
  if (auth) {
    headers['Authorization'] = `Bearer ${store.getState().token}`
  }

  return await axios({
    method,
    url: url.replace(/^\//g, ""),
    baseURL: baseUrl || "http:\\localhost:3000",
    data: isFile ? data : qs.stringify(data),
    responseType: isPdf ? "blob" : "json",
    params,    
    // auth: {
    //   username: 'QORogOtU31lg8ge4apNR',
    //   password: 'be8a294a0a7cfc4e12265bea5e35cccc051b00bdf9134b9736154231e939c04d'
    // },
    headers: (isFile || customHeader) ? {...headers} :  { ...headers, ...{
      'Content-Type': 'application/x-www-form-urlencoded'
    }},

    // `transformRequest` allows changes to the request data before it is sent to the server
    // transformRequest: [function (data, headers) {
    //   return data;
    // }],

    // `transformResponse` allows changes to the response data to be made before
    // transformResponse: [function (data) {
    //   return data;
    // }],
  })
    .then((res: AxiosResponse) => {
      // do common operatons before and after
      success(res)
    })
    .catch((err: AxiosError) => {
      // do common operatons before and after
      error(err)

      switch (err?.response?.status) {
      //   case 403:
      //     notif("danger", "You don't have permission to perform this action.")
      //     break
      //   case 400:
      //     notif("danger", err.response.data["hydra:description"])
      //     break
        case 401:
          store.dispatch(signout(() => {
            setTimeout(() => {
              window.location.href = '/'
            }, 1000);
            console.log('signed out')
          }))
          break
      //   case 404:
      //     notif(
      //       "danger",
      //       err?.response?.data?.detail || err?.response?.statusText
      //     )
      //     break
      //   case 502:
      //     notif("danger", "Bad gateway. Please contact to support.")
      //     break
      //   case 503:
      //     notif("danger", "Service unavailable. Please contact to support.")
      //     break
      //   default:
      //     notif("danger", "Some errors happened!")
      //     break
      }
    })
}
