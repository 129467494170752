import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Divider, Button, Select, Input } from "antd";

function DynamicField({
  consigneeRelations,
  setNeedContainers,
  needContainers,
}: any) {
  return (
    <Form.List name="containers">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Divider>نوع کانتینر درخواستی {index + 1}</Divider>
                <Form.Item
                  name={[index, "number"]}
                  label="تعداد کانتینر"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="نوع کانتینر" name={[index, "type"]}>
                  <Select>
                    {consigneeRelations.data?.containerType.map((item: any) => (
                      <Select.Option value={item.id}>
                        {item.generalName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="اندازه کانتینر"
                  name={[index, "size"]}
                  rules={[{ required: true }]}
                >
                  <Select>
                    {consigneeRelations.data?.containerSize.map((item: any) => (
                      <Select.Option value={item.id}>
                        {item.generalName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {fields.length > 0 ? (
                  <Button
                    type="default"
                    className="dynamic-delete-button"
                    onClick={() => {
                      if (fields.length === 1) {
                        setNeedContainers(false);
                      }
                      remove(field.name);
                    }}
                    icon={<MinusCircleOutlined />}
                  >
                    حذف از لیست کانتینر ها
                  </Button>
                ) : null}
              </div>
            ))}
            <Divider />
            <Form.Item>
              <Button
                type="ghost"
                onClick={() => {
                  setNeedContainers(true);
                  add();
                }}
                style={{
                  width: "100%",
                  margin: "0 auto",
                  border: "1px solid #eda64b",
                }}
              >
                <PlusOutlined />{" "}
                <span>
                  {needContainers
                    ? "اضافه کردن کانتینر"
                    : "نیاز به کانتینر دارم"}{" "}
                </span>
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}

export default DynamicField;
