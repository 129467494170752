import { Menu } from "antd";

import styles from "./Layout.module.scss";

function Header() {
  return (
    <div className={styles.footer}>
      <span className={styles.copywrite}>
        Copyright © 2021 MasireKala. All rights reserved. Version: 1.3.0
      </span>
      {/* <Menu
        className={styles.menu}
        selectedKeys={['none']}
        mode="horizontal"
      >
        <Menu.Item key="support">
          Support
        </Menu.Item>
        <Menu.Item key="helpCenter">
          Help Center
        </Menu.Item>
        <Menu.Item key="privacy">
          Privacy
        </Menu.Item>
        <Menu.Item key="termsOfService">
          Terms of Service
        </Menu.Item>
      </Menu>
     */}
    </div>
  );
}

export default Header;
