import { Button } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Box from '../../components/AuthBox/AuthBox'
import { signinPassword, signinPhoneCode, signinEmailCode } from '../../redux/actions'
import { IState } from '../../redux/types'

import styles from './Signin.module.scss'

function Singin() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const signin = useSelector((state: IState) => state.signin)

  return (
    <div className={styles.container}>
      <Box
        title={location.state?.userType === 2 ? 'ورود شرکت حمل و نقل و لجستیک' : 'ورود صاحبان کالا'}
        description='رمز عبور خود را وارد کنید.'
        actions={[
          {
            label: 'بازگشت',
            onClick: () => {
              console.log('come here')
              history.push(`/signin`, { userType: location.state?.userType })
            },
            main: false,
            type: 'default',
          },
          {
            label: 'ارسال',
            onClick: (values) => {
              dispatch(signinPassword({ ...values, userType: location.state?.userType }, (data) => {
                history.push(`/panel/home`)
              }))
            },
            main: true,
            type: 'primary',
          },
        ]}
        form={[
          { 
            name: 'password',
            label: 'رمز عبور',
            type: 'password',
            rules: [
              { required: true, message: 'رمز عبور خود را وارد کنید!' },
              { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, message: 'رمزعبور حداقل 8 کاراکتر شامل عدد، حروف کوچک و بزرگ و کاراکتر خاص '},
            ],
          }
        ]}
        footer={(
          <div>
            <Button type='link' onClick={() => {
              dispatch(signinPhoneCode(() => {
                history.push('/code', { type: 'signin', userType: location.state?.userType })
              }))
            }}>ارسال رمز یکبار مصرف از طریق پیامک</Button>
            <br />
            <Button type='link' onClick={() => {
              dispatch(signinEmailCode(() => {
                history.push('/code', { type: 'signin', userType: location.state?.userType })
              }))
            }}>ارسال رمز یکبار مصرف از طریق ایمیل</Button>
          </div>
        )}
        loading={signin.loading}
      >
      </Box>
    </div>
  )
}

export default Singin
