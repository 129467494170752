import {
  Form,
  Spin,
  Row,
  Col,
  Space,
  DatePicker,
  Button,
  Input,
  Select,
  Switch,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

import Card from "../../../components/Card/Card";
import { addOrder } from "../../../redux/actions";
import { IState } from "../../../redux/types";

import styles from "./AddOrder.module.scss";
import DynamicField from "./Contaners";
import React from "react";

const { TextArea } = Input;
const { Option } = Select;

function AddOrder() {
  const history = useHistory();
  const location = useLocation();
  const addOrderState = useSelector((state: IState) => state.addOrder);
  const consigneeRelations = useSelector(
    (state: IState) => state.consigneeRelations
  );
  const [needContainers, setNeedContainers] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  React.useEffect(() => {
    console.log("needContainers", needContainers);
  }, [needContainers]);

  return (
    <Card>
      {consigneeRelations.data.dgCode.length === 0 ? (
        <h3 style={{ width: "100%", textAlign: "center" }}>
          شما مجاز به ثبت استعلام کرایه حمل نمی باشید لطفا قبل از ثبت استعلام
          اطلاعات کاربری خود را از طریق پروفایل من کامل نمایید
        </h3>
      ) : (
        <Spin spinning={addOrderState.loading}>
          <Form
            layout="vertical"
            initialValues={location?.state?.data}
            form={form}
            onFinish={(data) => {
              console.log("data1111::", {
                ...data,
                needContainers,
                containers: needContainers ? data.containers : [],
                dgCodeId: data.dgCodeId
                  ? data.dgCodeId
                  : consigneeRelations.data.dgCode[0]?.id,
                etl: moment(data.etl).format("x"),
                qty: data.qty ? data.qty.toEnDigit() : "",
                netWeight: data.netWeight ? data.netWeight.toEnDigit() : "",
                grossWeight: data.grossWeight
                  ? data.grossWeight.toEnDigit()
                  : "",
                customValue: data.customValue
                  ? data.customValue.toEnDigit()
                  : "",
              });

              dispatch(
                addOrder(
                  location?.state?.data ? "PUT" : "POST",
                  {
                    ...data,
                    needContainers,
                    containers: needContainers ? data.containers : [],
                    dgCodeId: data.dgCodeId
                      ? data.dgCodeId
                      : consigneeRelations.data.dgCode[0]?.id,
                    etl: moment(data.etl).format("x"),
                    qty: data.qty ? data.qty.toEnDigit() : "",
                    netWeight: data.netWeight ? data.netWeight.toEnDigit() : "",
                    grossWeight: data.grossWeight
                      ? data.grossWeight.toEnDigit()
                      : "",
                    customValue: data.customValue
                      ? data.customValue.toEnDigit()
                      : "",
                  },
                  () => {
                    form.resetFields();
                    history.push("/panel/home");
                  },
                  location?.state?.data?.id
                )
              );
            }}
          >
            <div className={styles.detailContainer}>
              <h3 className={styles.title}>مشخصات کالا</h3>
              <hr className={styles.divider} />
              <Row gutter={[30, 10]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="commodityTypeId"
                    label="نوع کالا"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Select allowClear showSearch optionFilterProp="children">
                      {consigneeRelations.data.commodityType.map(
                        (item: any) => (
                          <Option value={item.id}>{item.commodityName}</Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    label="تعداد کالا"
                    name="qty"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    label="مبدا"
                    name="loadingPortId"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Select allowClear showSearch optionFilterProp="children">
                      {consigneeRelations.data.loadingPort.map((item: any) => (
                        <Option value={item.id}>{item.locationName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="dischargingPortId"
                    label="مقصد"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Select allowClear showSearch optionFilterProp="children">
                      {consigneeRelations.data.dischargingPort.map(
                        (item: any) => (
                          <Option value={item.id}>{item.locationName}</Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="netWeight"
                    label="وزن ناخالص"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="grossWeight" label="وزن خالص">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="packageTypeId"
                    label="نوع بسته بندی"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Select allowClear showSearch optionFilterProp="children">
                      {consigneeRelations.data.packageType.map((item: any) => (
                        <Option value={item.id}>{item.generalName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="dgCodeId" label="نوع کالای خطرناک">
                    <Select
                      defaultValue={consigneeRelations.data.dgCode[0]?.id}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      {consigneeRelations.data.dgCode.map(
                        (item: any, index) => (
                          <Option value={item.id}>{item.generalName}</Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="customValue"
                    label="ارزش گمرکی کالا"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="currencyId"
                    label="واحد ارز"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Select allowClear showSearch optionFilterProp="children">
                      {consigneeRelations.data.currency.map((item: any) => (
                        <Option value={item.id}>{item.generalName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="etl"
                    label="زمان تقریبی مورد نظر جهت تحویل بار به منظور بارگیری"
                  >
                    <Input allowClear type="datetime-local" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <DynamicField
                    needContainers={needContainers}
                    setNeedContainers={setNeedContainers}
                    consigneeRelations={consigneeRelations}
                  />
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="goodDescription"
                    label="شرح کالا"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <h3 className={styles.title}>فرستنده کالا (Shipper)</h3>
              <hr className={styles.divider} />
              <Row gutter={[30, 10]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="shipperFirstNameFa"
                    label="Name"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item label="نام‌حقیقی/حقوقی" name="shipperFirstNameEn">
                    <Input />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label="First Name"
                  name="shipperFirstNameEn"
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="shipperLastNameEn"
                  label="Last Name"
                >
                  <Input />
                </Form.Item>
              </Col> */}
                <Col span={24}>
                  <Form.Item name="shipperAddress" label="آدرس">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="shipperPhoneNumber"
                    label="شماره تماس"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <h3 className={styles.title}>گیرنده کالا (Consignee)</h3>
              <hr className={styles.divider} />
              <Row gutter={[30, 10]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="consigneeFirstNameFa"
                    label="Name"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    label="نام‌حقیقی/حقوقی"
                    name="consigneeFirstNameEn"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label="First Name"
                  name="consigneeFirstNameEn"
                  rules={[{ required: true, message: 'مقدار را وارد کنید!' }]}
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="consigneeLastNameEn"
                  label="Last Name"
                  rules={[{ required: true, message: 'مقدار را وارد کنید!' }]}
                >
                  <Input />
                </Form.Item>
              </Col> */}
                <Col span={24}>
                  <Form.Item name="consigneeAddress" label="آدرس">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="consigneePhoneNumber" label="شماره تماس">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <h3 className={styles.title}>
                مشخصات شخص مخاطب در بندر مقصد (Notify party)
              </h3>
              <hr className={styles.divider} />
              <Row gutter={[30, 10]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="notifyFirstNameFa"
                    label="Name"
                    rules={[{ required: true, message: "مقدار را وارد کنید!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item label="نام‌حقیقی/حقوقی" name="notifyFirstNameEn">
                    <Input />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label="First Name"
                  name="notifyFirstNameEn"
                  rules={[{ required: true, message: 'مقدار را وارد کنید!' }]}
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="notifyLastNameEn"
                  label="Last Name"
                  rules={[{ required: true, message: 'مقدار را وارد کنید!' }]}
                >
                  <Input />
                </Form.Item>
              </Col> */}
                <Col span={24}>
                  <Form.Item name="notifyAddress" label="آدرس">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="notifyPhoneNumber" label="شماره تماس">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Row>
              <Col span={24} className="alignEnd">
                <Space size={16}>
                  <Button htmlType="submit" size="large" type="primary">
                    ذخیره
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Spin>
      )}
    </Card>
  );
}

export default AddOrder;
