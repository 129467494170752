import { ReactNode } from 'react'
import { Row, Col, Button, Space, Modal } from 'antd'

import styles from './index.module.scss'

interface IProps {
  visible: boolean
  onCancel: () => void
  onConfirm: () => void
  children: ReactNode
}

export default function Confirm({ visible, onCancel, onConfirm, children}: IProps) {
  return (
  <Modal
      visible={visible}
      closable={false}
      onOk={onCancel}
      onCancel={onCancel}
      width={400}
      footer={false}
    >
      {children}
      <Row>
        <Col span={24} className={styles.confirmModalActionsContainer} >
          <Space size={16}>
            <Button style={{ width: 100 }} size="large" onClick={onCancel}>خیر</Button>
            <Button style={{ width: 100 }} type="primary" size="large" onClick={onConfirm}>بله</Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  )
}
