import { useEffect } from "react";
import { Alert, Row, Col, Rate, Button, Spin } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import api from "../../../utils/api";

import Card from "../../../components/Card/Card";
import LOGO from "../../../assets/images/logo.png";
import styles from "./index.module.scss";
import { getOrderInquiries } from "../../../redux/actions";
// import { getImage } from "../../../utils/getImage";
import { IState } from "../../../redux/types";

function Index() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const orderInquiries = useSelector((state: IState) => state.orderInquiries);
  const consigneeRelations = useSelector(
    (state: IState) => state.consigneeRelations
  );

  // const companies: ICompany[] = [
  //   {
  //     logo: LOGO,
  //     name: 'شرکت کشتیرانی دریابار',
  //     rate: 3.2,
  //     loadingDate: '2021/05/04',
  //     vesselType: 'لنج جنگی',
  //     perPrice: 650000,
  //   },
  //   {
  //     logo: LOGO,
  //     name: 'شرکت کشتیرانی بارمان',
  //     rate: 4.8,
  //     loadingDate: '2021/05/04',
  //     vesselType: 'کشتی تفریحی',
  //     perPrice: 742000,
  //   },
  // ]

  useEffect(() => {
    dispatch(getOrderInquiries(location.state?.orderId));
  }, []);

  return (
    <Card>
      <Row>
        <Alert
          message="پیشنهادات ذیل توسط شرکت های کشتیرانی ارائه شده است. لطفا به جزئیات و شرایط  درج شده در هر پیشنهاد دقت نمایید. با انتخاب هر کدام از پیشنهادات امکان مشاهده جزئیات و ثبت سفارش برای شما میسر میباشد."
          type="warning"
          closable
        />
      </Row>
      <Spin spinning={orderInquiries.loading}>
        <Row className={styles.container} gutter={[30, 30]}>
          {orderInquiries.data?.inquires?.map((item: any) => {
            // try {
            //   const res = getImage(item?.supplier.imageUrl);
            //   console.log("reSSs::", res);
            // } catch (e) {
            //   console.log("err ;sa;s;a", e);
            // }
            // let img;
            // api({
            //   method: "GET",
            //   url: `v1.0.0/image/${}`,
            //   baseUrl: "https://appsrv1.beautyles.com:8052",
            //   auth: true,
            //   success: (res: any) => {
            //     img = res.data;
            //     console.log("reSSs222222222222::", img);
            //   },
            //   error: (e: any) => {
            //     return e;
            //   },
            // });

            return (
              <Col>
                <div
                  className={`${styles.cardContainer} ${styles.introCompanyContainer}`}
                >
                  <img src={LOGO} alt="logo" />
                  <h2>{item?.supplier?.name}</h2>
                  {/* <Rate disabled defaultValue={0} value={item.rate} /> */}
                  <span className={styles.firstSpan}>
                    تاریخ بارگیری:{" "}
                    {item.firstLoadingDate
                      ? moment(parseInt(item.firstLoadingDate)).format(
                          "YYYY/MM/DD HH:mm"
                        )
                      : ""}
                  </span>
                  {/* <span>نوع شناور: {item.vesselType}</span> */}
                  <p>
                    کرایه حمل {item.price}{" "}
                    {
                      consigneeRelations.data.inqCurrency.find(
                        (el) => el.id === item?.inqCurrencyId
                      )?.generalName
                    }{" "}
                    به ازای هر{" "}
                    {
                      consigneeRelations.data.priceType.find(
                        (el) => el.id === item?.priceTypeId
                      )?.generalName
                    }
                  </p>
                  <div>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        history.push("/panel/home/inquiry", {
                          inquiryId: item.id,
                          orderId: location.state?.orderId,
                        });
                      }}
                    >
                      مشاهده جزئیات
                    </Button>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Spin>
    </Card>
  );
}

export default Index;
