import { ReactNode } from 'react'

import styles from './Card.module.scss'

interface IProps {
  children: ReactNode
}

function Card({ children }: IProps) {
  return (
    <div className={styles.container}>
      {children}
    </div>
  )
}

export default Card
