import { IState, IAction } from './types'

const initialState: IState = {
  token: '',
  signin: {
    loading: false,
    number: '',
  },
  addPaymentOrder: {
    loading: false
  },
  oneCarryOrder: {
    loading: false,
    data: null,
  },
  getPaymentOrder: {
    loading: false
  },
  allDocTypes: {
    loading: false,
    data: [],
  },
  carryOrderDocTypes: {
    loading: false,
    data: [],
  },
  documents: {
    loading: false,
    allDocTypes: null,
    docTypes: null,
  },
  signup: {
    loading: false,
    number: '',
  },
  addOrder: {
    loading: false,
  },
  acceptOrder: {
    loading: false,
  },
  rejectOrder: {
    loading: false,
  },
  user: {
    loading: false,
    data: {
      name: '',
      phone: '',
      userId: -1,
      userTypeId: 1,
      username: '',
      email: '',
    }
  },
  consigneeRelations: {
    loading: false,
    data: {
      containerType: [],
      currency: [],
      containerSize:[],
      dgCode: [],
      dischargingPort: [],
      loadingPort: [],
      packageType: [],
      commodityType: [],
      numberOfCommodityTypes: 0,
      numberOfCurrency: 0,
      numberOfDGCode: 0,
      numberOfDischargingPort: 0,
      numberOfLoadingPort: 0,
      numberOfPackageType: 0,
      inqCurrency: [],
      numberOfInqCurrency: 0,
      priceType: [],
      numberOfPriceType: 0,
    }
  },
  supplierRelations: {
    loading: false,
    data: {
      containerType: [],
      currency: [],
      containerSize: [],
      dgCode: [],
      dischargingPort: [],
      loadingPort: [],
      packageType: [],
      commodityType: [],
      numberOfCommodityTypes: 0,
      numberOfCurrency: 0,
      numberOfDGCode: 0,
      numberOfDischargingPort: 0,
      numberOfLoadingPort: 0,
      numberOfPackageType: 0,
      inqCurrency: [],
      numberOfInqCurrency: 0,
      priceType: [],
      numberOfPriceType: 0,
    }
  },
  consignee: {
    loading: false,
    data: {
      name: '',
      idCode: '',
      economicCode: '',
      phone: '',
      fax: '',
      email: '',
      contactName: '',
      address: '',
      postalCode: '',
      userId: '',
    }
  },
  supplier: {
    loading: false,
    data: {
      idCode: '',
      name: '', 
      economicCode: '', 
      bankAccount: '', 
      phone: '', 
      fax: '', 
      email: '',
      contactName: '',
      address: '',
      postalCode: '',
      webSite: '',
    }
  },
  oneCarryOrderSupp: {
    loading: false,
    data: null,
  },
  consigneeOrderList: {
    loading: false,
    params: {
      searchName: '',
      dateStart: '',
      dateEnd: '',
      dischargingPortId: 0,
      loadingPortId: 0,
      limit: 10,
      page: 1,
      count: 0,
    },
    data: {
      numberOfOrderListComplete: 0,
      numberOfOrderListConfirm: 0,
      numberOfOrderListHaveInquiries: 0,
      numberOfOrderListClosed: 0,
      orderListComplete: [],
      orderListConfirm: [],
      orderListHaveInquiries: [],
      orderListClosed: [],
    }
  },
  supplierOrderList: {
    loading: false,
    params: {
      searchName: '',
      dateStart: '',
      dateEnd: '',
      dischargingPortId: 0,
      loadingPortId: 0,
      limit: 10,
      page: 1,
      count: 0,
    },
    data: {
      numberOfOrderListUnseen: 0,
      numberOfOrderListAccept: 0,
      numberOfOrderFailed: 0,
      numberOfOrderConfirm: 0,
      orderListUnseen: [],
      orderListAccept: [],
      orderListFailed: [],
      orderListConfirm: [],
    }
  },
  vesselRelations: {
    loading: false,
    data: {
      flags: [],
      numberOfFlags: 0,
      numberOfRegisterPorts: 0,
      numberOfVesselTypes: 0,
      registerPorts: [],
      vesselTypes: []
    }
  },
  voyageRelations: {
    loading: false,
    data: {
      dischagingPort: [],
      dischargingPort: [],
      numberOfDischagingPort: 0,
      numberOfDischargingPort: 0
    }
  },
  inquiryRelations: {
    loading: false,
    data: {
      priceType: [],
      numberOfPriceType: 0,
      currency: [],
      numberOfCurrency: 0,
    }
  },
  vessels: {
    loading: false,
    params: {
      limit: 10,
      page: 1,
      count: 0,
    },
    data: []
  },
  addVessel: {
    loading: false,
  },
  deleteVessel: {
    loading: false,
  },
  voyages: {
    loading: false,
    params: {
      limit: 10,
      page: 1,
      count: 0,
    },
    data: []
  },
  addVoyage: {
    loading: false,
  },
  deleteVoyage: {
    loading: false,
  },
  orderInquiries: {
    loading: false,
    data: {
      currencyId: -1,
      customValue: -1,
      dGCodeId: -1,
      etl: '',
      goodDescription: '',
      grossWeight: '',
      id: -1,
      loadingPortId: -1,
      netWeight: '',
      orderDate: '',
      orderLongNo: '',
      orderShortNo: '',
      packageTypeId: -1,
      qty: '',
      consigneeFirstNameFa: '',
      consigneeAddress: '',
      consigneePhoneNumber: '',
      consigneeLastNameFa: '',
      consigneeFirstNameEn: '',
      consigneeLastNameEn: '',
      shipperFirstNameFa: '',
      shipperAddress: '',
      shipperPhoneNumber: '',
      shipperLastNameFa: '',
      shipperFirstNameEn: '',
      shipperLastNameEn: '',
      notifyFirstNameFa: '',
      notifyAddress: '',
      notifyPhoneNumber: '',
      notifyLastNameFa: '',
      notifyFirstNameEn: '',
      notifyLastNameEn: '',
    }
  },
  setVoyageToOrder: {
    loading: false,
  },
  test: null
}

function rootReducer(state = initialState, action: IAction) {
  const updatedState = { ...state }
  // seems this is a problem with typescript
  // @ts-ignore
  updatedState[action.type] = action.payload
  return updatedState
}

export default rootReducer
