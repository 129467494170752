import { useEffect } from 'react'
import { Spin, Form, Input, Button, Row, Col, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { getSupplierOrderList, rejectOrder } from '../../../../redux/actions'
import { IState } from '../../../../redux/types'

interface IProps {
  orderId: number | undefined
  closeModal: () => void
}

const { TextArea } = Input;

function InvoiceModal({ orderId, closeModal }: IProps) {
  const rejectOrderState = useSelector((state: IState) => state.rejectOrder)
  const supplierOrderList = useSelector((state: IState) => state.supplierOrderList)
  const dispatch = useDispatch()
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields()
  }, [orderId])

  return (
    <div>
      <Spin spinning={rejectOrderState.loading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={(info) => {
            dispatch(rejectOrder({ ...info, carryOrderId: orderId }, () => {
              form.resetFields()
              closeModal() 
              dispatch(getSupplierOrderList(supplierOrderList.params))
            }))
          }}
        >
          <div>
            <Row gutter={[30, 10]}>
              <Col span={24}>
                <Form.Item
                  label="توضیحات"
                  name="comment"
                >
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24} className='alignEnd' >
              <Space size={16}>
                <Button htmlType="submit" size="large" type="primary">حذف</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  )
}

export default InvoiceModal
