import { Button } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Box from '../../components/AuthBox/AuthBox'

import styles from './Signin.module.scss'

function Singin() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  return (
    <div className={styles.container}>
      <Box
        title={location.state?.userType === 2 ? 'ورود شرکت حمل و نقل و لجستیک' : 'ورود صاحبان کالا'}
        description={location.state?.userType === 2 ? 'در صورتیکه شما یک شرکت حمل و نقل و لجستیک هستید از این بخش وارد سیستم شوید' : 'در صورتیکه شما یک صاحب کالا هستید و درخواست جهت استعلام کرایه حمل دارید از این بخش وارد سیستم شوید.'}
        actions={[
          {
            label: 'ورود',
            onClick: (values) => {
              dispatch({
                type: 'signin',
                payload: {
                  loading: false,
                  number: values.number.toEnDigit()
                }
              })
              history.push('/signin/password', { userType: location.state?.userType || 1 })
            },
            main: true,
            type: 'primary',
          }
        ]}
        form={[
          { 
            name: 'number',
            label: 'شماره همراه',
            type: 'number',
            rules: [
              { required: true, message: 'شماره همراه خود را وارد کنید!' },
              // { pattern: /^09\d{9}$/, message: 'شماره همراه صحیح نیست!' },
            ],
          }
        ]}
        footer={(
          <div>
            <span>در صورتیکه در سامانه عضو نیستید</span>
            <Button type='link' onClick={() => {
              history.push('signup', { userType: location.state?.userType || 1 })
            }}>ثبت نام</Button>
            <span>کنید.</span>
          </div>
        )}
        loading={false}
      >
      </Box>
    </div>
  )
}

export default Singin
