import { useState } from 'react'
import { Table, Button, Row, Col, Alert, Space } from 'antd'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import InformInvoiceModal from '../../../../components/ConfirmModal/InformModal'
import { addPaymentOrder } from '../../../../redux/actions'
import { IState, IInvoice } from '../../../../redux/types'

interface IProps {
  orderId: number | undefined
  payment: IInvoice | undefined
  closeModal: () => void
}

function InvoiceModal({ payment, orderId, closeModal }: IProps) {
  const history = useHistory()
  const dispatch = useDispatch()
  const addPaymentState = useSelector((state: IState) => state.addPaymentOrder)

  const [confirmModal, setConfirmModal] = useState(false)
  
  const columns = [
    {
      title: 'وزن',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'هزینه خدمات به ازای هر تن (تومان)',
      dataIndex: 'perPrice',
      key: 'perPrice',
    },
    {
      title: 'مبلغ کل (تومان)',
      dataIndex: 'price',
      key: 'price',
    },
  ];

  return (
    <div>
      <Row gutter={[30, 30]}>
        <Col span={12}>
          <h3>هزینه خدمات الکترونیک وصدور اسناد الکترونیکی</h3>
          <Table
            rowKey='key'
            loading={false}
            scroll={{ x: true }}
            dataSource={[
              {
                key: '1',
                weight: payment?.invoiceDetails[0]?.qty,
                perPrice: payment?.invoiceDetails[0]?.unitPrice,
                price: payment?.invoiceDetails[0]?.price,
              },
              {
                key: '2',
                weight: '',
                perPrice: 'جمع کل',
                price: payment?.invoiceDetails[0]?.price,
              },
            ]}
            columns={columns}
            pagination={false}
          />
        </Col>
        <Col span={12}>
          <Alert
            message="پس از پرداخت هزینه خدمات الکترونیکی پیشنهادات ارائه شده برای شما نمایش داده خواهد شد و شما میتوانید به یکی از شرکت ها درخواست حمل خود را اعلام نمایید"
            type="warning"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className='alignEnd' >
          <Space size={16}>
            <Button htmlType="submit" size="large" type="primary" onClick={() => {
              if (orderId) {
                dispatch(addPaymentOrder(orderId, () => {
                  console.log('payed')
                  setConfirmModal(true)
                }))
              }
            }}>پرداخت</Button>
          </Space>
        </Col>
      </Row>
      <InformInvoiceModal
        onCancel={() => setConfirmModal(false)}
        onConfirm={() => {
          setConfirmModal(false)
          closeModal()
          history.push('/panel/home/inquiries', { orderId })
          // dispatch(restoreUser(userData.data.userId))
        }}
        visible={confirmModal}
      >
        <Alert
          message="پرداخت شما با موفقیت انجام شد و سفارش شما با شماره پیگیری ۲۴۶۲۳۷۶۴ ثبت گردید. جهت نمایش پیشنهادات لطفا  کلید ادامه رو انتخاب نمایید."
          type="success"
        />
      </InformInvoiceModal>
    </div>
  )
}

export default InvoiceModal
