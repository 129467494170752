import { useState, useEffect } from "react";
import {
  Form,
  Spin,
  Row,
  Col,
  Space,
  Button,
  Input,
  Select,
  Tag,
  Tabs,
  Table,
  Modal,
  TablePaginationConfig,
  Collapse,
} from "antd";
import { CheckOutlined, CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Card from "../../../components/Card/Card";
import { getSupplierOrderList, getVoyages } from "../../../redux/actions";
import { IState, IOrder } from "../../../redux/types";
import ShowModal from "./Modals/ShowModal";
import VoyageModal from "./Modals/VoyageModal";
import AcceptModal from "./Modals/AcceptModal";
import RejectModal from "./Modals/RejectModal";

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

function Home() {
  const voyageRelations = useSelector((state: IState) => state.voyageRelations);
  const voyagesState = useSelector((state: IState) => state.voyages);
  const supplierOrderList = useSelector(
    (state: IState) => state.supplierOrderList
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [orderModal, setOrderModal] = useState<[boolean, IOrder | undefined]>([
    false,
    undefined,
  ]);
  const [voyageModal, setVoyageModal] = useState<[boolean, number | undefined]>(
    [false, undefined]
  );
  const [checkModal, setCheckModal] = useState<[boolean, number | undefined]>([
    false,
    undefined,
  ]);
  const [rejectModal, setRejectModal] = useState<[boolean, number | undefined]>(
    [false, undefined]
  );

  useEffect(() => {
    dispatch(
      getSupplierOrderList({
        searchFilter: "unseen",
        limit: supplierOrderList.params.limit,
        page: 1,
      })
    );
    dispatch(getVoyages({}));
  }, [voyageModal]);

  function onChangeTable(pagination: TablePaginationConfig) {
    if (pagination.current && pagination.pageSize) {
      dispatch(
        getSupplierOrderList({
          ...supplierOrderList.params,
          page: pagination.current,
          limit: pagination.pageSize,
        })
      );
    }
  }

  function switchActiveKey(key: string) {
    switch (key) {
      case "1":
        return "unseen";
      case "2":
        return "accept";
      case "3":
        return "confirm";
      case "4":
        return "failed";
      default:
        break;
    }
  }

  function tabsHandler(activeKey: string) {
    dispatch(
      getSupplierOrderList({
        searchFilter: switchActiveKey(activeKey),
        limit: supplierOrderList.params.limit,
        page: 1,
      })
    );
  }

  function onShowSizeChange(current: number, size: number) {
    dispatch(
      getSupplierOrderList({
        ...supplierOrderList.params,
        limit: size,
        page: current,
      })
    );
  }

  const baseColumns = [
    {
      title: "شماره درخواست",
      dataIndex: "orderLongNo",
      key: "orderLongNo",
    },
    {
      title: "تاریخ و ساعت",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (td: any) => moment(parseInt(td)).format("YYYY/MM/DD HH:mm"),
    },
    {
      title: "مبدا",
      dataIndex: "loadingPortId",
      key: "loadingPortId",
      render: (td: any) => (
        <Tag>
          {
            voyageRelations.data.dischagingPort.find((item) => item.id === td)
              ?.locationName
          }
        </Tag>
      ),
    },
    {
      title: "مقصد",
      dataIndex: "dischargingPortId",
      key: "dischargingPortId",
      render: (td: any) => (
        <Tag>
          {
            voyageRelations.data.dischargingPort.find((item) => item.id === td)
              ?.locationName
          }
        </Tag>
      ),
    },
    // {
    //   title: 'نوع کالا',
    //   dataIndex: 'commudityID',
    //   key: 'commudityID',
    // },
    {
      title: "شرح کالا",
      dataIndex: "goodDescription",
      key: "goodDescription",
    },
    {
      title: "وزن خالص",
      dataIndex: "netWeight",
      key: "netWeight",
    },
    {
      title: "صاحب کالا",
      dataIndex: "netWeight",
      key: "netWeight",
      render: (text: any, row: any) => `${row.consigneeFirstNameFa}`,
    },
  ];

  const completeColumns = [
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, row: IOrder) => {
        return (
          <Space size={20}>
            <EyeOutlined
              onClick={() => {
                setOrderModal([true, row]);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const inProgressColumns = [
    {
      title: "شماره تماس",
      dataIndex: "number",
      key: "numberId",
      render: (text: any, row: any) => {
        if (row.consignee.user.mobileNo) {
          return row.consignee.user.mobileNo;
        } else {
          return "شماره تماسی ثبت نشده است.";
        }
      },
    },
    {
      title: "نام سفر",
      dataIndex: "voyageId",
      key: "voyageId",
      render: (text: any, row: any) => {
        if (!text || text === "") {
          return (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                dispatch(
                  getVoyages({}, () => {
                    setVoyageModal([true, row.id]);
                  })
                );
              }}
            >
              تخصیص سفر
            </Button>
          );
        } else {
          return (
            <Tag>
              {voyagesState.data.find((item) => item.id === text)?.voyageName}
            </Tag>
          );
        }
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, row: IOrder) => {
        return (
          <Space size={20}>
            <EyeOutlined
              onClick={() => {
                setOrderModal([true, row]);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const closedColumns = [
    {
      title: "سفر",
      dataIndex: "voyageId",
      key: "voyageId",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, row: any) => {
        return (
          <Space size={20}>
            <EyeOutlined
              onClick={() => {
                setOrderModal([true, row]);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const freshColumns = [
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, row: IOrder) => {
        return (
          <Space size={20}>
            <EyeOutlined
              onClick={() => {
                setOrderModal([true, row]);
              }}
            />
            <CheckOutlined
              style={{ color: "green" }}
              onClick={() => {
                setCheckModal([true, row.id]);
              }}
            />
            <CloseOutlined
              style={{ color: "red" }}
              onClick={() => {
                setRejectModal([true, row.id]);
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Card>
      <div>
        <Collapse defaultActiveKey="0">
          <Panel header="جست و جوی پیشرفته" key="1">
            <Form
              layout={window.innerWidth < 700 ? "vertical" : "horizontal"}
              initialValues={supplierOrderList.params}
              form={form}
              onFinish={(data) => {
                dispatch(
                  getSupplierOrderList({
                    ...data,
                    dateEnd: data.dateEnd
                      ? moment(data.dateEnd).format("x")
                      : "",
                    dateStart: data.dateStart
                      ? moment(data.dateStart).format("x")
                      : "",
                    page: 1,
                    limit: supplierOrderList.params.limit,
                  })
                );
              }}
            >
              <Row gutter={[30, 0]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item label="جستجو" name="searchName">
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  {" "}
                </Col>
                <Col xs={24} sm={24} md={24} lg={9}>
                  <Form.Item label="تاریخ شروع" name="dateStart">
                    <Input allowClear type="datetime-local" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9}>
                  <Form.Item name="dateEnd" label="تاریخ اتمام">
                    <Input allowClear type="datetime-local" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9}>
                  <Form.Item label="مبدا" name="loadingPortId">
                    <Select allowClear showSearch optionFilterProp="children">
                      {voyageRelations.data.dischagingPort.map((item: any) => (
                        <Option value={item.id}>{item.locationName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9}>
                  <Form.Item name="dischargingPortId" label="مقصد">
                    <Select allowClear showSearch optionFilterProp="children">
                      {voyageRelations.data.dischargingPort.map((item: any) => (
                        <Option value={item.id}>{item.locationName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  className="alignEnd"
                  style={{ alignItems: "flex-end", display: "flex" }}
                >
                  <Form.Item>
                    <Space size={16}>
                      <Button htmlType="submit" type="primary">
                        جستجو
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Panel>
        </Collapse>
      </div>
      <hr style={{ width: "100%" }} />
      <Spin spinning={supplierOrderList.loading || voyagesState.loading}>
        <Tabs defaultActiveKey="1" onChange={tabsHandler}>
          <TabPane tab="درخواست های مشاهده نشده" key="1">
            <Table
              rowKey="orderLongNo"
              loading={false}
              dataSource={supplierOrderList.data.orderListUnseen}
              columns={[...baseColumns, ...freshColumns]}
              onChange={onChangeTable}
              scroll={{ x: true }}
              pagination={{
                total: supplierOrderList.data?.numberOfOrderListUnseen,
                pageSize: supplierOrderList.params.limit,
                current: supplierOrderList.params.page,
                showSizeChanger: true,
                showTotal: (total: number) => `${total} مورد`,
                onShowSizeChange: onShowSizeChange,
                pageSizeOptions: ["10", "20", "50"],
                showTitle: false,
                locale: {
                  items_per_page: "",
                },
              }}
            />
          </TabPane>
          <TabPane tab="در انتظار اقدام" key="2">
            <Table
              rowKey="carryOrderID"
              scroll={{ x: true }}
              loading={false}
              dataSource={supplierOrderList.data.orderListAccept}
              columns={[...baseColumns, ...completeColumns]}
              onChange={onChangeTable}
              pagination={{
                total: supplierOrderList.params.count,
                pageSize: supplierOrderList.params.limit,
                current: supplierOrderList.params.page,
                showSizeChanger: true,
                showTotal: (total: number) => `${total} مورد`,
                onShowSizeChange: onShowSizeChange,
                pageSizeOptions: ["10", "20", "50"],
                showTitle: false,
                locale: {
                  items_per_page: "",
                },
              }}
            />
          </TabPane>
          <TabPane tab="تایید شده" key="3">
            <Table
              rowKey="carryOrderID"
              scroll={{ x: true }}
              loading={false}
              dataSource={supplierOrderList.data.orderListConfirm}
              columns={[...baseColumns, ...inProgressColumns]}
              onChange={onChangeTable}
              pagination={{
                total: supplierOrderList.params.count,
                pageSize: supplierOrderList.params.limit,
                current: supplierOrderList.params.page,
                showSizeChanger: true,
                showTotal: (total: number) => `${total} مورد`,
                onShowSizeChange: onShowSizeChange,
                pageSizeOptions: ["10", "20", "50"],
                showTitle: false,
                locale: {
                  items_per_page: "",
                },
              }}
            />
          </TabPane>
          <TabPane tab="بسته شده" key="4">
            <Table
              rowKey="carryOrderID"
              scroll={{ x: true }}
              loading={false}
              dataSource={supplierOrderList.data.orderListFailed}
              columns={[...baseColumns, ...closedColumns]}
              onChange={onChangeTable}
              pagination={{
                total: supplierOrderList.params.count,
                pageSize: supplierOrderList.params.limit,
                current: supplierOrderList.params.page,
                showSizeChanger: true,
                showTotal: (total: number) => `${total} مورد`,
                onShowSizeChange: onShowSizeChange,
                pageSizeOptions: ["10", "20", "50"],
                showTitle: false,
                locale: {
                  items_per_page: "",
                },
              }}
            />
          </TabPane>
        </Tabs>
        <Modal
          title="سفارش"
          visible={orderModal[0]}
          onOk={() => setOrderModal([false, undefined])}
          onCancel={() => setOrderModal([false, undefined])}
          width={800}
          footer={false}
        >
          <ShowModal data={orderModal[1]} />
        </Modal>
        <Modal
          title="تخصیص شناور"
          visible={voyageModal[0]}
          onOk={() => setVoyageModal([false, undefined])}
          onCancel={() => setVoyageModal([false, undefined])}
          width={400}
          footer={false}
        >
          <VoyageModal
            orderId={voyageModal[1]}
            closeModal={() => setVoyageModal([false, undefined])}
          />
        </Modal>
        <Modal
          title="پذیرفتن سفارش"
          visible={checkModal[0]}
          onOk={() => setCheckModal([false, undefined])}
          onCancel={() => setCheckModal([false, undefined])}
          width={800}
          footer={false}
        >
          <AcceptModal
            orderId={checkModal[1]}
            closeModal={() => setCheckModal([false, undefined])}
          />
        </Modal>
        <Modal
          title="رد سفارش"
          visible={rejectModal[0]}
          onOk={() => setRejectModal([false, undefined])}
          onCancel={() => setRejectModal([false, undefined])}
          width={600}
          footer={false}
        >
          <RejectModal
            orderId={rejectModal[1]}
            closeModal={() => setRejectModal([false, undefined])}
          />
        </Modal>
      </Spin>
    </Card>
  );
}

export default Home;
